<template>
  <PriorHeader />
  <div class="graph-page-wrapper">
    <div class="shadow-ellipse shadow-ellipse-right"></div>
    <div class="shadow-ellipse shadow-ellipse-left"></div>
    <div class="patient-information-form-wrapper">
      <div class="patient-information-page-title">
        <h1>{{ pdfSubmission.insuranceProvider }}</h1>
        <h1>
          {{ pdfSubmission.insurancePlanType }}
          <span v-if="pdfSubmission.insurancePlanType && pdfSubmission.insurancePlanType.length">|</span>
          {{ pdfSubmission.patientInfo.state }}
        </h1>
      </div>
      <GreenCirclePreloader v-if="preloader" />
      <div v-if="priorAuthFormDetails && priorAuthFormDetails.formSrcUrl" class="form">
        <div class="patient-information-part">
          <h5>Drug Prior Authorization Request Form</h5>
          <hr class="bold-hr" />
          <div class="download-template">
            <a :href="priorAuthFormDetails.formSrcUrl" target="_blank"> Download Prior Authorization Form </a>
          </div>
          <div v-if="pdfSubmission.formSubmitted" class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span><p class="label">Prior Authorization Form</p></span>
                    </td>
                    <td>
                      <div class="input-wrapper">
                        <div class="download">
                          <a :href="pdfSubmission.formSubmitted" target="_blank">Download</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span><p class="label">Upload Completed Prior Authorization Form</p></span>
                    </td>
                    <td>
                      <UploadFileComponent
                        :download-destination="`formSubmitted`"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <span v-if="!isFormUploaded && formButtonClicked" class="input-error-notification">
            Please upload completed Prior Authorization Form.
          </span>
        </div>
        <SupplementalDocumentsUploadComponent
          :supplemental-docs="pdfSubmission.supportingDocs"
          :download-destination="`supportingDocs`"
          @add-free-form-supplemental-docs="addDownloadLink" />
        <div class="guidelines-checklist">
          <div class="checklist-item">
            <input
              id="send-to-plan"
              v-model="sendToPlan"
              type="checkbox"
              @change="setSubmitForProcessingValue('sendToPlan')" />
            <label for="send-to-plan">
              <span class="label">Fax this submission to plan for me</span>
            </label>
          </div>
          <div class="checklist-item">
            <input
              id="already-faxed"
              v-model="alreadyFaxed"
              type="checkbox"
              @change="setSubmitForProcessingValue('alreadyFaxed')" />
            <label for="already-faxed">
              <span class="label">I have already faxed this submission and want to track it here</span>
            </label>
          </div>
          <span v-if="!faxSettingSelected && formButtonClicked" class="input-error-notification">
            Please select whether you need us to fax uploaded form for you.
          </span>
        </div>
        <SignaturePadComponent
          :signature="pdfSubmission.signature"
          :form-button-clicked="formButtonClicked"
          @update-signature="updateSignature" />
        <div class="buttons-wrapper">
          <button class="next-button" @click="submitPdfForm">Submit</button>
        </div>
        <div class="errors-container">
          <span v-if="error" class="input-error-notification">
            Sorry, something went wrong. Please contact us at
            <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
          </span>
        </div>
      </div>
      <div v-else class="form">
        <div class="light-background-section">
          <h5>Fill out Digital Form or Upload Form</h5>
          <hr class="light-hr" />
          <div class="accented-paragraph">
            It looks like we do not have the PDF version of this form. If you have this form, you can choose to upload a
            completed PDF form to submit PA and track its status. Or fill out a digital form and we will find the form
            and submit it for you.
          </div>
          <div class="buttons-wrapper">
            <button class="left-active-button" @click="toggleForm('digital-from')">Fill Out Digital Form</button>
            <button class="next-button" @click="toggleForm('upload-form')">Upload Completed Form</button>
          </div>
        </div>
        <div v-if="digitalForm">
          <AllFormStepsView />
        </div>
        <div v-if="uploadOwnTemplate">
          <div class="patient-information-part">
            <h5>Drug Prior Authorization Request Form</h5>
            <hr class="bold-hr" />
            <div class="accented-paragraph">
              Upload a completed form to track your submission and contribute to the prior authorization knowledge base.
            </div>
            <div v-if="pdfSubmission.formSubmitted" class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span><p class="label">Prior Authorization Form</p></span>
                    </td>
                    <td>
                      <div class="input-wrapper">
                        <div class="download">
                          <a :href="pdfSubmission.formSubmitted" target="_blank">Download</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td class="input-wrapper">
                      <span><p class="label">Upload Completed Prior Authorization Form</p></span>
                    </td>
                    <td>
                      <UploadFileComponent
                        :download-destination="`formSubmitted`"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span v-if="!isFormUploaded && formButtonClicked" class="input-error-notification">
              Please upload completed Prior Authorization Form.
            </span>
          </div>
          <SupplementalDocumentsUploadComponent
            :supplemental-docs="pdfSubmission.supportingDocs"
            :download-destination="`supportingDocs`"
            @add-free-form-supplemental-docs="addDownloadLink" />
          <div class="guidelines-checklist">
            <div class="checklist-item">
              <input
                id="send-to-plan"
                v-model="sendToPlan"
                type="checkbox"
                @change="setSubmitForProcessingValue('sendToPlan')" />
              <label for="send-to-plan">
                <span class="label">Fax this submission to plan for me</span>
              </label>
            </div>
            <div class="checklist-item">
              <input
                id="already-faxed"
                v-model="alreadyFaxed"
                type="checkbox"
                @change="setSubmitForProcessingValue('alreadyFaxed')" />
              <label for="already-faxed">
                <span class="label">I have already faxed this submission and want to track it here</span>
              </label>
            </div>
            <span v-if="!faxSettingSelected && formButtonClicked" class="input-error-notification">
              Please select whether you need us to fax uploaded form for you.
            </span>
          </div>
          <SignaturePadComponent
            :signature="pdfSubmission.signature"
            :form-button-clicked="formButtonClicked"
            @update-signature="updateSignature" />
          <div class="buttons-wrapper">
            <button class="next-button" @click="submitPdfForm">Submit</button>
          </div>
          <div class="errors-container">
            <span v-if="error" class="input-error-notification">
              Sorry, something went wrong. Please contact us at
              <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import AllFormStepsView from "@/pages/AllFormStepsView";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import UploadFileComponent from "@/components/UploadFileComponent";
import SupplementalDocumentsUploadComponent from "@/components/SupplementalDocumentsUploadComponent";
import SignaturePadComponent from "@/components/SignaturePadComponent";
import { mainServices } from "@/services/mainServices";
import { orderInfoService } from "@/services/orderInfoService";
import { uploadService } from "@/services/uploadService";
import { storeToRefs } from "pinia";
import { usePdfSubmissionStore } from "@/stores/pdfSubmissionStore";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const preloader = ref(false);
const { pdfSubmission } = storeToRefs(usePdfSubmissionStore());
const priorAuthFormDetails = ref(null);
const formButtonClicked = ref(false);
const textractResultsLoading = ref(false);
const error = ref(false);
const sendToPlan = ref(false);
const alreadyFaxed = ref(false);
const digitalForm = ref(false);
const uploadOwnTemplate = ref(false);
const faxSettingSelected = computed(() => pdfSubmission.value.submitForProcessing !== null);

onMounted(() => {
  for (let i = 0; i < 5; i++) {
    const supportingDocObject = { label: null, links: [] };
    pdfSubmission.value.supportingDocs[i] = supportingDocObject;
  }
  if (route.params.id) {
    getPriorAuthPdfFormTemplate(route.params.id);
  } else {
    priorAuthFormDetails.value = "Form is missing";
  }
});

function toggleForm(value) {
  if (value === "digital-from") {
    digitalForm.value = true;
    uploadOwnTemplate.value = false;
  } else {
    digitalForm.value = false;
    uploadOwnTemplate.value = true;
  }
}

const isFormUploaded = computed(() => {
  const value = pdfSubmission.value.formSubmitted;
  return value !== null && value.trim() !== "";
});

const setSubmitForProcessingValue = (value) => {
  if (value === "sendToPlan") {
    pdfSubmission.value.submitForProcessing = sendToPlan.value ? true : null;
    alreadyFaxed.value = false;
  } else {
    pdfSubmission.value.submitForProcessing = alreadyFaxed.value ? false : null;
    sendToPlan.value = false;
  }
};

async function getPriorAuthPdfFormTemplate(id) {
  preloader.value = true;
  priorAuthFormDetails.value = await mainServices.getPriorAuthForm(id, { medication: pdfSubmission.value.medication });
  pdfSubmission.value.priorAuthFormId = priorAuthFormDetails.value.priorAuthForm;
  preloader.value = false;
}

const addDownloadLink = (downloadLinkInfo) => {
  if (downloadLinkInfo.storeVar === "formSubmitted") {
    pdfSubmission.value[downloadLinkInfo.storeVar] = downloadLinkInfo.link;
    textractPatientInfo(downloadLinkInfo.uploadKey);
  } else {
    if (!pdfSubmission.value[downloadLinkInfo.storeVar][downloadLinkInfo.storeVarIndex]) {
      pdfSubmission.value[downloadLinkInfo.storeVar][downloadLinkInfo.storeVarIndex] = { label: null, links: [] };
    }
    pdfSubmission.value[downloadLinkInfo.storeVar][downloadLinkInfo.storeVarIndex].links.push(downloadLinkInfo.link);
    pdfSubmission.value[downloadLinkInfo.storeVar][downloadLinkInfo.storeVarIndex].label = downloadLinkInfo.fileName;
  }
};

async function textractPatientInfo(uploadKey) {
  textractResultsLoading.value = true;
  const response = await uploadService.startTextractForUploadedFileURL(uploadKey);
  if (response.data && response.data.query_results) {
    pdfSubmission.value.patientInfo.firstName = response.data.query_results.first_name
      ? response.data.query_results.first_name
      : "N/a";
    pdfSubmission.value.patientInfo.lastName = response.data.query_results.last_name
      ? response.data.query_results.last_name
      : "N/a";
    pdfSubmission.value.patientInfo.dob = response.data.query_results.dob ? response.data.query_results.dob : "N/a";
    pdfSubmission.value.patientInfo.insuranceId = response.data.query_results.insurance_id
      ? response.data.query_results.insurance_id
      : "N/a";
  }
  textractResultsLoading.value = false;
}

async function submitPdfForm() {
  formButtonClicked.value = true;
  await new Promise((resolve) => setTimeout(resolve, 0));
  const missingInput = document.getElementsByClassName("input-error-notification");
  if (missingInput.length === 0) {
    preloader.value = true;
    if (textractResultsLoading.value) {
      await new Promise((resolve) => {
        const unsubscribe = setInterval(() => {
          if (!textractResultsLoading.value) {
            clearInterval(unsubscribe);
            resolve();
          }
        }, 100);
      });
    }
    try {
      error.value = false;
      await orderInfoService.submitPdfForm(pdfSubmission.value);
      preloader.value = false;
      router.push({ name: "all-submissions" });
    } catch (err) {
      error.value = true;
      preloader.value = false;
    }
  } else {
    setTimeout(() => {
      const parentDiv = missingInput[0].parentNode;
      window.scrollTo({
        top: parentDiv.offsetTop,
        behavior: "smooth",
      });
    }, 0);
  }
}

function updateSignature(signature) {
  pdfSubmission.value.signature = signature;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_requirements-page.scss";
@import "../styles/pages/_patient-information-form.scss";
</style>
