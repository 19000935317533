<template>
  <MiniGreenCirclePreloader v-if="showPreloader" />
  <div v-else class="upload-section">
    <input
      ref="pdfInput"
      type="file"
      accept=".tiff, .pdf, .png, .jpg, .jpeg"
      :disabled="props.disabled"
      @change="handleFileUpload" />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { uuid } from "vue-uuid";
import { uploadService } from "@/services/uploadService";
import MiniGreenCirclePreloader from "@/components/MiniGreenCirclePreloader";

const props = defineProps({
  downloadDestination: {
    type: String,
    default() {
      return "";
    },
  },
  downloadLabel: {
    type: String,
    default() {
      return "";
    },
  },
  downloadDestinationIndex: {
    type: Number,
    default() {
      return null;
    },
  },
  disabled: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const emit = defineEmits(["downloadLink"]);
const showPreloader = ref(false);
let uploadKey = null;
const downloadLink = ref(null);
const fileName = ref(null);

const pdfInput = ref(null);
const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg", "image/tiff"];

const handleFileUpload = async (event) => {
  showPreloader.value = true;
  const file = event.target.files[0];
  if (!file) {
    showPreloader.value = false;
    return;
  }
  if (!allowedFileTypes.includes(file.type)) {
    showPreloader.value = false;
    return;
  }
  if (file.size > 5000000) {
    showPreloader.value = false;
    return;
  }
  const uuidv4 = uuid.v4();
  uploadKey = uuidv4;
  fileName.value = file.name;
  uploadKey = uuidv4 + "/" + fileName.value;
  downloadLink.value = await uploadService.uploadFile(file, uploadKey);
  showPreloader.value = false;
};

watch(downloadLink, (newVal) => {
  if (newVal) {
    emit("downloadLink", {
      link: newVal,
      storeVar: props.downloadDestination,
      label: props.downloadLabel,
      storeVarIndex: props.downloadDestinationIndex,
      fileName: fileName.value,
      uploadKey: uploadKey,
    });
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/components/_upload-file-component.scss";
</style>
