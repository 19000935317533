const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const fetchData = async (url, timeout, interval) => {
  const startTime = Date.now();

  while (Date.now() < startTime + timeout) {
    const response = await fetch(url);
    if (response.ok) {
      return response;
    }
    await sleep(interval);
  }

  throw new Error("Request timed out");
};

const withTimeout = (promise, timeout) => {
  return new Promise(async (resolve, reject) => {
    setTimeout(() => {
      resolve(null);
    }, timeout);

    try {
      const response = await promise;
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const parseResponse = async (response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.includes("application/json")
    ? response.json()
    : contentType && contentType.includes("text/csv")
    ? response.text()
    : response.blob();
};

export const fetchAndReturnData = async (urls) => {
  const startTime = Date.now();
  let responseObject = {};

  while (Date.now() - startTime < 60000) {
    try {
      // Fetch the URLs with short polls and wait for their responses
      const responses = await withTimeout(Promise.all(urls.map((url) => fetchData(url, 1200000, 10000))), 60000);

      if (responses === null) {
        await sleep(10000);
        continue;
      }

      // Parse the responses based on their content type (JSON, CSV, or PDF)
      const parsedResponses = await Promise.all(responses.map((res) => parseResponse(res)));

      // Attach the parsed responses to an object
      responseObject = parsedResponses.reduce((acc, data, index) => {
        acc[`response${index + 1}`] = data;
        return acc;
      }, {});

      // If valid responses are received, stop polling
      break;
    } catch (error) {
      await sleep(10000);
    }
  }

  return responseObject;
};
