<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div class="light-background-section">
        <h5>Request For Expedited Review</h5>
        <hr class="light-hr" />
        <div class="expedited-checkmark-wrapper">
          <input
            id="expedited-review-24h"
            v-model="expeditedReviewWithin24h"
            type="checkbox"
            :disabled="!props.isEditable"
            @change="emit('updateOrderInfoField', 'expeditedReviewWithin24h', expeditedReviewWithin24h)" />
          <label for="expedited-review-24h" class="expedited-review-1">
            CHECK THIS BOX IF YOU BELIEVE YOU NEED A DECISION WITHIN 24 HOURS (if you have a supporting statement from
            your prescriber, attach it to this request).
          </label>
        </div>
        <span v-if="expeditedReviewWithin24h">
          <p>Must provide reason for expedited review:</p>
          <input
            id="expedited-review-reason"
            v-model="expeditedReviewStatement"
            type="text"
            placeholder=""
            :readonly="!props.isEditable"
            @change="emit('updateOrderInfoField', 'expeditedReviewStatement', expeditedReviewStatement)" />
          <p>
            NOTE: If you are asking for a formulary or tiering exception, your prescriber MUST provide a statement
            supporting your request. Requests that are subject to prior authorization (or any other utilization
            management requirement), may require supporting information. Your prescriber may use the attached
            “Supporting Information for an Exception Request or Prior Authorization” to support your request.
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(["updateOrderInfoField"]);

const props = defineProps({
  expeditedReviewWithin24h: {
    type: Boolean,
    default() {
      return false;
    },
  },
  expeditedReviewStatement: {
    type: String,
    default() {
      return "";
    },
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const expeditedReviewWithin24h = ref(props.expeditedReviewWithin24h);
const expeditedReviewStatement = ref(props.expeditedReviewStatement);
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
