import { defineStore } from "pinia";
import { ref } from "vue";

export const useSelectedSubmissionStore = defineStore("selected-submission", () => {
  const selectedSubmission = ref({});

  return {
    selectedSubmission,
  };
});
