<template>
  <div class="radio-select-flow-option-wrapper">
    <GreenCirclePreloader v-if="props.dataPullPreloader" />
    <div v-else class="option-selection-form">
      <h5>Select one option how to add patient records</h5>
      <hr />
      <div>
        <input id="manualEntry" v-model="fillDataOption" type="radio" name="fillDataOption" value="manualEntry" />
        <label for="manualEntry" class="option-title">Option 1: I will manually enter information</label>
      </div>

      <div>
        <input id="pullFromEHR" v-model="fillDataOption" type="radio" name="fillDataOption" value="pullFromEHR" />
        <label for="pullFromEHR" class="option-title">Option 2: Pull data from EHR</label>
        <form :class="{ inactive: fillDataOption !== 'pullFromEHR' }" @submit.prevent="pullDataFromEHR">
          <div>
            <div class="grid-row">
              <label for="patient-id">Patient ID</label>
              <input
                id="patient-id"
                v-model="ehrDataRequest.patientId"
                type="text"
                placeholder=""
                :disabled="fillDataOption !== 'pullFromEHR'"
                @keyup="(event) => sendFormByEnterClicking(event, pullDataFromEHR)" />
            </div>
            <span
              v-if="!isPatientIdValid && formButtonClicked && fillDataOption === 'pullFromEHR'"
              class="input-error-notification">
              Please enter a valid EHR patient ID.
            </span>
          </div>
          <br />
          <button type="submit" :disabled="fillDataOption !== 'pullFromEHR'">Pull from EHR</button>
        </form>
      </div>

      <div>
        <input
          id="pullFromReferringProvider"
          v-model="fillDataOption"
          type="radio"
          name="fillDataOption"
          value="pullFromReferringProvider" />
        <label for="pullFromReferringProvider" class="option-title">Option 3: Pull data from referring provider</label>
        <form
          :class="{ inactive: fillDataOption !== 'pullFromReferringProvider' }"
          @submit.prevent="pullDataFromReferringProvider">
          <div>
            <div class="grid-row">
              <label for="insitute-name">Institute Name</label>
              <input
                id="insitute-name"
                v-model="referringProviderRequest.institute"
                type="text"
                placeholder=""
                :disabled="fillDataOption !== 'pullFromReferringProvider'"
                @keyup="(event) => sendFormByEnterClicking(event, pullDataFromReferringProvider)" />
            </div>
            <span
              v-if="!isInstituteNameValid && formButtonClicked && fillDataOption === 'pullFromReferringProvider'"
              class="input-error-notification">
              Please enter a valid institute name.
            </span>
          </div>
          <div class="insurance-state">
            <label for="npi">NPI number</label>
            <input
              id="npi"
              v-model="referringProviderRequest.npi"
              type="text"
              placeholder=""
              :disabled="fillDataOption !== 'pullFromReferringProvider'"
              @keyup="(event) => sendFormByEnterClicking(event, pullDataFromReferringProvider)" />
            <span
              v-if="!isNpiValid && formButtonClicked && fillDataOption === 'pullFromReferringProvider'"
              class="input-error-notification">
              Please enter a valid NPI number. Must be 10 digits.
            </span>
          </div>
          <button type="submit" :disabled="fillDataOption !== 'pullFromReferringProvider'">Pull Data</button>
        </form>
      </div>

      <div>
        <input
          id="uploadPatientChart"
          v-model="fillDataOption"
          type="radio"
          name="fillDataOption"
          value="uploadPatientChart" />
        <label for="uploadPatientChart" class="option-title">Option 4: Upload patient chart</label>
        <div>
          <div v-if="patientChartForParsing && patientChartForParsing.fileName">
            Uploaded: {{ patientChartForParsing.fileName }}
          </div>
          <div v-else>
            <UploadFileComponent
              class="input-wrapper"
              :disabled="fillDataOption !== 'uploadPatientChart'"
              @download-link="processUploadedPatientChart" />
          </div>
        </div>
      </div>
    </div>
    <span v-if="props.dataPullError" class="input-error-notification">
      Sorry, something went wrong. Please contact us at
      <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later. <br />
      <span v-for="error in props.specificDataPullError" :key="error"> {{ error }}</span>
    </span>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import UploadFileComponent from "@/components/UploadFileComponent.vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import { sendFormByEnterClicking } from "@/utils";
import { FORM_VALIDATION_PATTERNS } from "@/utils/constants";

const props = defineProps({
  dataPullPreloader: {
    type: Boolean,
    required: true,
  },
  dataPullError: {
    type: Boolean,
    required: true,
  },
  specificDataPullError: {
    type: String,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(["setAutoFillingOption", "pullAutoFillingData"]);

const formButtonClicked = ref(false);
const fillDataOption = ref("manualEntry");

watch(fillDataOption, (newValue) => {
  emit("setAutoFillingOption", newValue);
  resetRequestVariables();
});

function resetRequestVariables() {
  ehrDataRequest.value.patientId = null;
  referringProviderRequest.value.institute = null;
  referringProviderRequest.value.npi = null;
  patientChartForParsing.value = null;
}

const referringProviderRequest = ref({
  institute: null,
  npi: null,
});

const ehrDataRequest = ref({
  patientId: null,
});

const patientChartForParsing = ref(null);

function pullDataFromEHR() {
  formButtonClicked.value = true;
  if (isPatientIdValid.value) {
    emit("pullAutoFillingData", ehrDataRequest.value);
  }
}

function pullDataFromReferringProvider() {
  formButtonClicked.value = true;
  if (isInstituteNameValid.value && isNpiValid.value) {
    emit("pullAutoFillingData", referringProviderRequest.value);
  }
}

const processUploadedPatientChart = (downloadLinkInfo) => {
  formButtonClicked.value = true;
  if (downloadLinkInfo) {
    patientChartForParsing.value = downloadLinkInfo;
    emit("pullAutoFillingData", downloadLinkInfo);
  }
};

const isPatientIdValid = computed(() => {
  const value = ehrDataRequest.value.patientId;
  return value !== null && value.trim() !== "";
});

const isInstituteNameValid = computed(() => {
  const value = referringProviderRequest.value.institute;
  return value !== null && value.trim() !== "";
});

const isNpiValid = computed(() => {
  const npi = referringProviderRequest.value.npi;
  return npi !== "" && FORM_VALIDATION_PATTERNS.NPI_PATTERN.test(npi);
});
</script>

<style lang="scss" scoped>
@import "../styles/components/_radio-select-flow-option-component.scss";
</style>
