<template>
  <div class="checkbox-in-table">
    <input :id="`${inputFieldId}`" type="checkbox" :checked="isChecked" @click="clickOnOption" />
    <label :for="`${inputFieldId}`" class="">Attach</label>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { generateRandom4DigitNumber } from "@/utils";

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
});

const isChecked = ref(false);
const inputFieldId = generateRandom4DigitNumber();

const emit = defineEmits(["updateRecord"]);

function clickOnOption() {
  isChecked.value = !isChecked.value;
  emit("updateRecord", props.record, isChecked.value);
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
