<template>
  <PriorHeader />
  <div v-if="showBenefitInvestigationForm" class="patient-information-form-wrapper">
    <div class="patient-information-page-title">
      <h1>Do Prior Auth</h1>
      <h1>Fill In Patient And Prescriber Information</h1>
    </div>
    <div class="form">
      <div class="light-background-section">
        <h5>Optional: Upload patient intake form to populate form below</h5>
        <hr class="light-hr" />
        <TesseractOCRComponent />
      </div>
    </div>
    <GreenCirclePreloader v-if="preloader" />
    <PatientInformationFormPreview
      v-if="!preloader"
      :order-info="orderInfo"
      :is-editable="true"
      :form-button-clicked="formButtonClicked"
      :patient-address-book="patientAddressBook"
      :provider-address-book="providerAddressBook"
      @update-patient-info="updatePatientInfo"
      @update-provider-info="updateProviderInfo"
      @update-order-info-field="updateOrderInfoField"
      @set-patient-info-from-address-book="setPatientInfoFromAddressBook"
      @set-provider-info-from-address-book="setProviderInfoFromAddressBook" />
    <SignaturePadBenefitVerificationComponent
      v-if="!preloader"
      :signature="orderInfo.signature"
      :form-button-clicked="formButtonClicked"
      @update-signature="updateSignature" />
    <div v-if="!preloader" class="form">
      <div class="buttons-wrapper">
        <button
          class="return-button"
          :class="{ 'button-clicked': draftSavingInProgress }"
          :disabled="draftSavingInProgress"
          @click="savePartialOrderInfo">
          Save Draft
        </button>
        <button class="next-button" :disabled="draftSavingInProgress" @click="requestBenefitVerification">
          Request Benefit Verification
        </button>
      </div>
    </div>
    <span v-if="errors.length > 0" class="input-error-notification">
      <span v-for="error in errors" :key="error">{{ error }}</span>
      Sorry, something went wrong. Please contact us at
      <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
    </span>
  </div>
  <div v-if="showSuccessPage">
    <SuccessPage :title="benefitInvestigationTitle" :message="benefitInvestigationMessage" />
  </div>
  <PriorFooter />
</template>

<script setup>
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import PriorHeader from "@/components/PriorHeader.vue";
import PriorFooter from "@/components/PriorFooter.vue";
import PatientInformationFormPreview from "@/pages/PatientInformationFormPreview.vue";
import TesseractOCRComponent from "@/components/TesseractOCRComponent.vue";
import SignaturePadBenefitVerificationComponent from "@/components/SignaturePadBenefitVerificationComponent.vue";
import SuccessPage from "@/pages/SuccessPage.vue";

import { ref, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { orderInfoService } from "@/services/orderInfoService";
import { tryParseApiErrors, clearOrderInfoStorage } from "@/utils";
import { useOrderInfoStore } from "@/stores/orderInfoStore";

const { orderInfo } = storeToRefs(useOrderInfoStore());
const formButtonClicked = ref(false);
const patientAddressBook = ref([]);
const providerAddressBook = ref([]);
const errors = ref([]);
const preloader = ref(false);
const draftSavingInProgress = ref(false);
const showBenefitInvestigationForm = ref(true);
const showSuccessPage = ref(false);
const benefitInvestigationTitle = "Complete";
const benefitInvestigationMessage = "You will get notified by email when the Benefit Verification is complete.";

onMounted(async () => {
  preloader.value = true;
  const addressBooks = await orderInfoService.getAvailableAddressBooks();
  patientAddressBook.value = addressBooks.patientAddressBook;
  providerAddressBook.value = addressBooks.providerAddressBook;
  orderInfo.value.formStep = "Benefit Verification";
  preloader.value = false;
});

onUnmounted(() => {
  clearOrderInfoStorage();
});

function updatePatientInfo(field, value) {
  orderInfo.value = { ...orderInfo.value, patientInfo: { ...orderInfo.value.patientInfo, [field]: value } };
}

function updateProviderInfo(field, value) {
  orderInfo.value.providerInfo[field] = value;
}

function setPatientInfoFromAddressBook(patientInfo, id) {
  orderInfo.value.patientInfo = patientInfo;
  orderInfo.value.patientInfo.id = id;
}

function setProviderInfoFromAddressBook(providerInfo, id) {
  orderInfo.value.providerInfo = providerInfo;
  orderInfo.value.providerInfo.id = id;
}

function updateOrderInfoField(field, value) {
  orderInfo.value[field] = value;
}

function updateSignature(signature) {
  orderInfo.value.signature = signature;
}

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  if (!orderInfo.value.orderKey) {
    const newOrder = await orderInfoService.createEmptyBenefitVerificationRequest(orderInfo.value);
    orderInfo.value.orderKey = newOrder.orderKey;
  }
  await orderInfoService.saveBenefitVerificationRequest(orderInfo.value.orderKey, orderInfo.value);
  draftSavingInProgress.value = false;
}

async function requestBenefitVerification() {
  errors.value = [];
  formButtonClicked.value = true;
  await new Promise((resolve) => setTimeout(resolve, 0));
  const missingInput = document.getElementsByClassName("input-error-notification");
  if (missingInput.length === 0) {
    draftSavingInProgress.value = true;
    try {
      if (!orderInfo.value.orderKey) {
        const newOrder = await orderInfoService.createEmptyOrder(orderInfo.value);
        orderInfo.value.orderKey = newOrder.orderKey;
      }
      orderInfo.value.formStep = "Patient and Provider Information";
      await orderInfoService.saveBenefitVerificationRequest(orderInfo.value.orderKey, orderInfo.value);
      errors.value = [];
      clearOrderInfoStorage();
      showBenefitInvestigationForm.value = false;
      showSuccessPage.value = true;
      window.scrollTo(0, 0);
    } catch (err) {
      errors.value = tryParseApiErrors(err);
    }
    draftSavingInProgress.value = false;
  } else {
    setTimeout(() => {
      const parentDiv = missingInput[0].parentNode;
      window.scrollTo({
        top: parentDiv.offsetTop,
        behavior: "smooth",
      });
    }, 0);
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
