<template>
  <div class="report-container">
    <h1>Medical Report</h1>

    <div class="header">
      <h3>{{ orderInfo.providerInfo.officeName }}</h3>
      <p>
        {{ orderInfo.providerInfo.providerAddress1 }}, {{ orderInfo.providerInfo.providerCity }},
        {{ orderInfo.providerInfo.providerState }},
        {{ orderInfo.providerInfo.providerZip }}
      </p>
      <p>{{ formatPhoneNumber(orderInfo.providerInfo.officePhone) }}</p>
    </div>

    <h2>Patient Identification and Demographics</h2>
    <table>
      <tr>
        <th>Full Legal Name</th>
        <td>
          {{ orderInfo.patientInfo.prefix ? orderInfo.patientInfo.prefix : "" }}
          {{ orderInfo.patientInfo.firstName ? orderInfo.patientInfo.firstName : "" }}
          {{ orderInfo.patientInfo.middleName ? orderInfo.patientInfo.middleName : "" }}
          {{ orderInfo.patientInfo.lastName ? orderInfo.patientInfo.lastName : "" }}
          {{ orderInfo.patientInfo.suffix ? orderInfo.patientInfo.suffix : "" }}
        </td>
      </tr>
      <tr>
        <th>Date of Birth</th>
        <td>{{ orderInfo.patientInfo.dob }}</td>
      </tr>
      <tr>
        <th>Contact Details</th>
        <td>{{ orderInfo.patientInfo.phone }}</td>
      </tr>
      <tr>
        <th>Home Address</th>
        <td>
          {{ orderInfo.patientInfo.address1 }}, {{ orderInfo.patientInfo.city }}, {{ orderInfo.patientInfo.state }},
          {{ orderInfo.patientInfo.zip }}
        </td>
      </tr>
      <tr>
        <th>Insurance Information</th>
        <td>{{ orderInfo.insuranceProvider }}: {{ orderInfo.patientInfo.insuranceId }}</td>
      </tr>
    </table>

    <div v-if="orderInfo.drugsTried && orderInfo.drugsTried.length">
      <h2>Medication History</h2>
      <table>
        <thead>
          <tr>
            <th>Medication</th>
            <th>Date Started</th>
            <th>Date Ended</th>
            <th>Result of Trial</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="medication in orderInfo.drugsTried" :key="medication.drugTried">
            <td>{{ medication.drugTried }}</td>
            <td>{{ medication.startDate }}</td>
            <td>{{ medication.endDate }}</td>
            <td>{{ medication.resultOfTrial }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-for="record in props.patientRecords" :key="record">
      <h2>{{ record.category }}<span v-if="record.dateOfRecord"> - </span>{{ record.dateOfRecord }}</h2>
      <ul v-for="item in record.details" :key="item.details">
        <li>{{ item }}</li>
      </ul>
    </div>
    <div class="footer">
      <p>Document generated by Lamar Health on {{ new Date().toLocaleString() }}</p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useOrderInfoStore } from "@/stores/orderInfoStore";

const { orderInfo } = storeToRefs(useOrderInfoStore());

const props = defineProps({
  patientRecords: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
});

function formatPhoneNumber(phoneStr) {
  const cleaned = ("" + phoneStr).replace(/\D/g, "");
  let formattedPhoneNumber = "";
  if (cleaned.length === 10) {
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      formattedPhoneNumber = "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  } else if (cleaned.length === 11) {
    let match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      formattedPhoneNumber = "1 (" + match[1] + ") " + match[2] + "-" + match[3];
    }
  } else {
    formattedPhoneNumber = phoneStr;
  }
  return formattedPhoneNumber;
}
</script>

<style lang="scss" scoped>
.report-container {
  font-family: "DM Sans", sans-serif;
  margin: 10px;
  padding: 50px;
  border: 1px dashed $gray-900;
  border-radius: 34px;
}
h1,
h2,
h3,
h4 {
  text-align: center;
}

.header {
  text-align: center;
  h3 {
    margin: 0;
  }
  p {
    margin: 5px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.footer {
  text-align: center;
  margin-top: 50px;
  color: $gray-600;
}
</style>
