<template>
  <PriorHeader />
  <div class="smart-engine-wrapper">
    <h1>Review Prior Authorization</h1>
    <div class="shadow-ellipse shadow-ellipse-right"></div>
    <div class="shadow-ellipse shadow-ellipse-left"></div>
    <GreenCirclePreloader v-if="showPreloader" />
    <AllFormStepsView
      v-if="!showPreloader && orderInfo.orderKey"
      :address-books="addressBooks"
      :is-sponsored="isSponsored"
      :dispensing-info-required="dispensingInfoRequired" />
    <div v-if="errors.length > 0" class="error-container">
      <h2>Looks like you can't access this Prior Authorization</h2>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import PriorFooter from "@/components/PriorFooter";
import PriorHeader from "@/components/PriorHeader";
import AllFormStepsView from "@/pages/AllFormStepsView";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";

import { ref, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores";
import { orderInfoService } from "@/services/orderInfoService";
import { useRoute } from "vue-router";
import { clearOrderInfoStorage } from "@/utils";
import { tryParseApiErrors } from "@/utils";

const errors = ref([]);
const route = useRoute();
const showPreloader = ref(false);
const addressBooks = ref(null);
const isSponsored = ref(false);
const dispensingInfoRequired = ref(false);
const { orderInfo } = storeToRefs(useOrderInfoStore());

onMounted(() => {
  if (route.params.id) {
    getOrderDetails(route.params.id);
  }
});

onUnmounted(() => {
  clearOrderInfoStorage();
});

async function getOrderDetails(id) {
  showPreloader.value = true;
  try {
    const order = await orderInfoService.getOrderDetails(id);
    isSponsored.value = order.isSponsored;
    dispensingInfoRequired.value = order.dispensingInfoRequired && order.dispensingInfoRequired.length ? true : false;
    addressBooks.value = await orderInfoService.getAvailableAddressBooks();
    orderInfo.value = order.orderInfo;
  } catch (err) {
    errors.value = tryParseApiErrors(err);
  }
  showPreloader.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_smart-engine-container.scss";
</style>
