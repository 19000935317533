<template>
  <PriorHeader />
  <div class="patient-information-form-wrapper">
    <div
      v-if="(selectedSubmission && selectedSubmission.status) || (orderInfo && orderInfo.status)"
      class="patient-information-page-title">
      <h1>
        {{ patientNameAndOrderKey }}
      </h1>
      <h1>{{ orderStatus }}</h1>
    </div>

    <GreenCirclePreloader v-if="showPreloader" />
    <div v-if="orderInfo">
      <div>
        <img id="priorAuthForm" :src="`${orderInfo.formSubmitted}`" alt="Uploaded PA Form" />
      </div>
      <div v-if="orderInfo.supportingDocs && orderInfo.supportingDocs.length" class="form">
        <div
          v-if="orderInfo.supportingDocs[0].links && orderInfo.supportingDocs[0].links.length"
          class="light-background-section">
          <h5>Supplemental Documents</h5>
          <hr class="light-hr" />
          <ul id="supplementalDocs">
            <li v-for="supportingDoc in orderInfo.supportingDocs" :key="supportingDoc">
              <div v-if="supportingDoc.links.length">
                <p>{{ supportingDoc.label ? supportingDoc.label : "Supplemental Document" }}</p>
                <div v-for="docLink in supportingDoc.links" :key="docLink">
                  <img :src="`${docLink}`" alt="Supplemental Document" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import { orderInfoService } from "@/services/orderInfoService";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import { tryParseApiErrors } from "@/utils";
import { useSelectedSubmissionStore } from "@/stores/selectedSubmissionStore";
import { storeToRefs } from "pinia";
import Viewer from "viewerjs";

const { selectedSubmission } = storeToRefs(useSelectedSubmissionStore());

const route = useRoute();
const errors = ref([]);
const showPreloader = ref(false);
const orderInfo = ref(null);

onMounted(() => {
  if (route.params.id) {
    getOrderInfo(route.params.id);
  }
});

onUnmounted(() => {
  clearSelectedSubmissionStorage();
});

const patientNameAndOrderKey = computed(() => {
  let patientNameAndOrderKey = "";
  if (selectedSubmission.value && selectedSubmission.value.status) {
    if (
      selectedSubmission.value.patientInfo &&
      selectedSubmission.value.patientInfo.firstName &&
      selectedSubmission.value.patientInfo.lastName
    ) {
      patientNameAndOrderKey = `${selectedSubmission.value.patientInfo.firstName} ${selectedSubmission.value.patientInfo.lastName} - ${selectedSubmission.value.orderKey}`;
    } else {
      patientNameAndOrderKey = `Patient Information TBA - ${selectedSubmission.value.orderKey}`;
    }
  } else if (orderInfo.value && orderInfo.value.status) {
    if (orderInfo.value.patientInfo && orderInfo.value.patientInfo.firstName && orderInfo.value.patientInfo.lastName) {
      patientNameAndOrderKey = `${orderInfo.value.patientInfo.firstName} ${orderInfo.value.patientInfo.lastName} - ${orderInfo.value.orderKey}`;
    } else {
      patientNameAndOrderKey = `Patient Information TBA - ${orderInfo.value.orderKey}`;
    }
  }
  return patientNameAndOrderKey;
});

const orderStatus = computed(() => {
  let orderStatus = "";
  if (selectedSubmission.value && selectedSubmission.value.status) {
    orderStatus = `Status: ${selectedSubmission.value.status}`;
  } else if (orderInfo.value && orderInfo.value.status) {
    orderStatus = `Status: ${orderInfo.value.status}`;
  }
  return orderStatus;
});

async function getOrderInfo(id) {
  showPreloader.value = true;
  try {
    orderInfo.value = await orderInfoService.getPdfSubmissionDetails(id);
    setTimeout(() => {
      const viewerPAForm = new Viewer(document.getElementById("priorAuthForm"), {
        inline: true,
        viewed() {
          viewerPAForm.zoomTo(1);
        },
      });
      if (
        orderInfo.value.supportingDocs &&
        orderInfo.value.supportingDocs.length &&
        orderInfo.value.supportingDocs[0].links.length
      ) {
        new Viewer(document.getElementById("supplementalDocs"));
      }
    }, 1000);
  } catch (err) {
    errors.value = tryParseApiErrors(err);
  }
  showPreloader.value = false;
}

function clearSelectedSubmissionStorage() {
  selectedSubmission.value = {};
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
@import "viewerjs/dist/viewer.css";
</style>
