<template>
  <PriorHeader />
  <div class="registered-form-wrapper">
    <h1 v-if="!registerEmailSent" class="registration-page-title">Create Your Account</h1>
    <GreenCirclePreloader v-if="showPreloader" />
    <div v-if="registerEmailSent">
      <h1 class="registration-page-title">We are on it!</h1>
      <div class="final-registered-notification">
        <div class="form">
          <div class="envelop-wrapper">
            <img src="@/assets/images/envelop.svg" alt="envelop" />
          </div>
          <p>Check your email to get started with your account</p>
        </div>
      </div>
    </div>
    <div v-else-if="!showPreloader && !registerEmailSent" class="registration-form form">
      <div class="two-input-fields-per-row">
        <div class="first-name">
          <label for="first-name">First Name</label>
          <input
            id="first-name"
            v-model="userInfo.first_name"
            type="text"
            placeholder="John"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isFieldNotEmpty('first_name') && formButtonClicked" class="input-error-notification">
            Please enter your first name.
          </span>
        </div>
        <div class="last-name">
          <label for="last-name">Last Name</label>
          <input
            id="last-name"
            v-model="userInfo.last_name"
            type="text"
            placeholder="Snow"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isFieldNotEmpty('last_name') && formButtonClicked" class="input-error-notification">
            Please enter your last name.
          </span>
        </div>
      </div>
      <div class="two-input-fields-per-row">
        <div>
          <label for="phone-number">Phone Number</label>
          <input
            id="phone-number"
            v-model="userInfo.phone"
            type="tel"
            placeholder="1-123-456-7890"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isPhoneNumberValid && formButtonClicked" class="input-error-notification">
            Please enter a valid phone number.
          </span>
        </div>
        <div>
          <label for="phone-number-extension">Phone Ext.</label>
          <input
            id="phone-number-extension"
            v-model="userInfo.phone_extension"
            type="text"
            placeholder="123"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
        </div>
      </div>
      <div class="two-input-fields-per-row">
        <div>
          <label for="fax">Fax</label>
          <input
            id="fax"
            v-model="userInfo.fax"
            type="text"
            placeholder="1-123-456-7890"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isFaxValid && formButtonClicked" class="input-error-notification">
            Please enter a valid fax number.
          </span>
        </div>
        <div class="your-email">
          <label for="your-email">Email Address</label>
          <input
            id="your-email"
            v-model="userInfo.email"
            type="text"
            placeholder="example@findsunrise.com"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isEmailValid && formButtonClicked" class="input-error-notification">
            Please enter a valid email address.
          </span>
        </div>
      </div>
      <div class="two-input-fields-per-row">
        <div>
          <label for="tin">TIN</label>
          <input
            id="tin"
            v-model="userInfo.tin"
            type="text"
            placeholder="123456789"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isTinValid && formButtonClicked" class="input-error-notification">
            Please enter a valid TIN. Must be 9 digits.
          </span>
        </div>
        <div>
          <label for="npi">NPI</label>
          <input
            id="npi"
            v-model="userInfo.npi"
            type="text"
            placeholder="1234567890"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isNpiValid && formButtonClicked" class="input-error-notification">
            Please enter a valid NPI. Must be 10 digits.
          </span>
        </div>
      </div>
      <div>
        <label for="practice-title">Practice Title</label>
        <input
          id="practice-title"
          v-model="userInfo.practice_title"
          type="text"
          placeholder=""
          @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
        <span v-if="!isFieldNotEmpty('practice_title') && formButtonClicked" class="input-error-notification">
          Please enter a practice title on which behalf you will be submitting prior authorization requests.
        </span>
      </div>
      <div class="two-input-fields-per-row">
        <div>
          <label for="specialty">Specialty</label>
          <input
            id="specialty"
            v-model="userInfo.specialty"
            type="text"
            placeholder=""
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isFieldNotEmpty('specialty') && formButtonClicked" class="input-error-notification">
            Please select specialty of your practice.
          </span>
        </div>
        <div>
          <label for="license-or-certification">License or Certification</label>
          <input
            id="license-or-certification"
            v-model="userInfo.license_or_certification"
            type="text"
            placeholder=""
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span
            v-if="!isFieldNotEmpty('license_or_certification') && formButtonClicked"
            class="input-error-notification">
            Please enter license or certification.
          </span>
        </div>
      </div>
      <div v-if="insurances" class="two-input-fields-per-row">
        <div>
          <label for="states-covered">States Covered</label>
          <CheckboxMultipleSelectComponent
            :options="states"
            :selected-options="userInfo.states"
            :placeholder="`Select States Covered`"
            @selection-change="(event) => handleSelectionChange(event, 'states_served')" />
          <span v-if="!isArrayFieldNotEmpty('states_served') && formButtonClicked" class="input-error-notification">
            Please select at least 1 state.
          </span>
        </div>
        <div>
          <label>Insurance Providers Accepted</label>
          <CheckboxMultipleSelectComponent
            :options="insurances"
            :selected-options="userInfo.insurances_accepted"
            :placeholder="`Select Accepted Insurance Providers`"
            :allow-free-text-input="true"
            @selection-change="(event) => handleSelectionChange(event, 'insurances_accepted')" />
          <span
            v-if="!isArrayFieldNotEmpty('insurances_accepted') && formButtonClicked"
            class="input-error-notification">
            Please select at least 1 insurance provider.
          </span>
        </div>
      </div>
      <div class="two-input-fields-per-row">
        <div class="password">
          <label for="password">Password</label>
          <input
            id="password"
            v-model="userInfo.password"
            type="password"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isPasswordValid && formButtonClicked" class="input-error-notification">
            Please create a password with at least 11 characters, at least 1 uppercase and 1 lowercase letter, 1 number,
            and 1 symbol.
          </span>
        </div>
        <div class="confirm-password">
          <label for="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            v-model="passwordConfirmation"
            type="password"
            @keyup="(event) => sendFormByEnterClicking(event, registerUser)" />
          <span v-if="!isPasswordMatchValid && formButtonClicked" class="input-error-notification">
            Passwords must match.
          </span>
        </div>
      </div>
      <div class="terms-and-conditions">
        <input id="terms-and-conditions" v-model="acceptTermsOfService" type="checkbox" />
        <label for="terms-and-conditions">
          I accept
          <a href="https://www.dopriorauth.com/company/tos" target="_blank">
            Terms of Service & Business Associate Agreement
          </a>
        </label>
        <span v-if="!acceptTermsOfService && formButtonClicked" class="input-error-notification">
          Must accept terms of service before continuing.
        </span>
        <br />
        <span class="agreement">
          NOTICE: The information you provide in response to these questions will be used by Do Prior Auth during the
          account set-up validation process. All information provided will be subject to our
          <a href="https://www.dopriorauth.com/company/tos" target="_blank">
            Terms of Service and Business Associate Agreement </a
          >.
        </span>
      </div>
      <div class="button-wrapper">
        <button @click="registerUser">Create Account</button>
        <span v-if="errors.length > 0" class="input-error-notification">
          <span v-for="error in errors" :key="error">{{ error }}</span>
        </span>
      </div>
      <div class="auxiliary-account-links">
        <span class="have-an-account">
          Already have an account ?
          <router-link :to="{ name: 'login' }">Login</router-link>
        </span>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import CheckboxMultipleSelectComponent from "@/components/CheckboxMultipleSelectComponent";
import authService from "@/services/authService";
import { mainServices } from "@/services/mainServices";
import { tryParseApiErrors, sendFormByEnterClicking } from "@/utils";
import { FORM_VALIDATION_PATTERNS } from "@/utils/constants";
import { usaStates } from "@/utils/usaStates";

const userInfo = ref({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone: "",
  phone_extension: "",
  specialty: "",
  tin: "",
  npi: "",
  practice_title: "",
  license_or_certification: "",
  states_served: [],
  insurances_accepted: [],
});

const states = ref([]);
const insurances = ref(null);
const formButtonClicked = ref(false);
const errors = ref([]);
const showPreloader = ref(false);
const registerEmailSent = ref(false);
const passwordConfirmation = ref(null);
const acceptTermsOfService = ref(false);

onBeforeMount(async () => {
  for (let stateData of Object.values(usaStates)) {
    states.value.push(stateData.name);
  }
  insurances.value = await mainServices.getInsuranceProviders();
});

onMounted(() => {
  document
    .querySelector("#phone-number")
    .addEventListener("keypress", (e) =>
      e.target.value.length === 1
        ? (e.target.value = e.target.value + "-")
        : e.target.value.length === 5
        ? (e.target.value = e.target.value + "-")
        : e.target.value.length === 9
        ? (e.target.value = e.target.value + "-")
        : null
    );
  document
    .querySelector("#fax")
    .addEventListener("keypress", (e) =>
      e.target.value.length === 1
        ? (e.target.value = e.target.value + "-")
        : e.target.value.length === 5
        ? (e.target.value = e.target.value + "-")
        : e.target.value.length === 9
        ? (e.target.value = e.target.value + "-")
        : null
    );
});

// Validators
function isFieldNotEmpty(fieldValue) {
  if (!fieldValue) return false;
  const value = userInfo.value[fieldValue];
  return value !== null && value.trim() !== "";
}

function isArrayFieldNotEmpty(fieldValue) {
  if (!fieldValue) return false;
  return userInfo.value[fieldValue].length > 0;
}

const isEmailValid = computed(() => {
  const email = userInfo.value.email;
  return email !== "" && FORM_VALIDATION_PATTERNS.EMAIL_PATTERN.test(email);
});

const isPasswordValid = computed(() => {
  const password = userInfo.value.password;
  return FORM_VALIDATION_PATTERNS.PASSWORD_PATTERN.test(password);
});

const isPasswordMatchValid = computed(() => {
  return userInfo.value.password === passwordConfirmation.value;
});

const isPhoneNumberValid = computed(() => {
  const phoneNumber = userInfo.value.phone;
  return phoneNumber !== "" && FORM_VALIDATION_PATTERNS.PHONE_NUMBER_PATTERN.test(phoneNumber);
});

const isFaxValid = computed(() => {
  const fax = userInfo.value.fax;
  return fax !== "" && FORM_VALIDATION_PATTERNS.PHONE_NUMBER_PATTERN.test(fax);
});

const isTinValid = computed(() => {
  const tin = userInfo.value.tin;
  return tin !== "" && FORM_VALIDATION_PATTERNS.TIN_PATTERN.test(tin);
});

const isNpiValid = computed(() => {
  const npi = userInfo.value.npi;
  return npi !== "" && FORM_VALIDATION_PATTERNS.NPI_PATTERN.test(npi);
});

async function registerUser() {
  formButtonClicked.value = true;

  if (
    isFieldNotEmpty("first_name") &&
    isFieldNotEmpty("last_name") &&
    isFieldNotEmpty("specialty") &&
    isFieldNotEmpty("practice_title") &&
    isFieldNotEmpty("license_or_certification") &&
    isArrayFieldNotEmpty("states_served") &&
    isArrayFieldNotEmpty("insurances_accepted") &&
    isTinValid.value &&
    isNpiValid.value &&
    isPhoneNumberValid.value &&
    isFaxValid.value &&
    isEmailValid.value &&
    isPasswordValid.value &&
    isPasswordMatchValid.value &&
    acceptTermsOfService.value
  ) {
    try {
      showPreloader.value = true;
      await authService.register(userInfo.value);
      registerEmailSent.value = true;
      errors.value = [];
    } catch (error) {
      errors.value = tryParseApiErrors(error);
    }
  } else {
    setTimeout(() => {
      const missingInput = document.getElementsByClassName("input-error-notification");
      if (missingInput.length > 0) {
        const parentDiv = missingInput[0].parentNode;
        window.scrollTo({
          top: parentDiv.offsetTop,
          behavior: "smooth",
        });
      }
    }, 0);
  }
  showPreloader.value = false;
}

function handleSelectionChange(selectedOptions, filterOptions) {
  userInfo.value[filterOptions] = selectedOptions;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_authorisation-forms.scss";
</style>
