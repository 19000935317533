<template>
  <div>
    <div>
      <div v-if="!selectedTerm" class="input-elements-group" :class="{ expanded: isSearchDropdownVisible }">
        <label :for="`search-icd10-code-${props.data.searchId}`">
          {{ props.data.label + (props.required ? "*" : "") }}
        </label>
        <input
          :id="`search-icd10-code-${props.data.searchId}`"
          v-model="typedText"
          type="text"
          placeholder="Start typing ICD-10 Code or Diagnosis"
          @keyup.esc="cleanInputAndHideDropdown" />
      </div>
    </div>
    <div v-if="isSearchDropdownVisible" class="search-dropdown-block">
      <div v-if="loading">
        <ul>
          <li>
            <span name="list-item-text">Loading...</span>
          </li>
        </ul>
      </div>
      <div v-else>
        <div v-if="inputTooShort" class="hint">
          <ul>
            <li>
              <span name="list-item-text">Enter at least {{ minTermLen }} characters for autocomplete</span>
            </li>
          </ul>
        </div>
        <ul v-else>
          <li v-for="item in suggestions" :key="item[0]" @click="selectICD10Code(item)">
            <span name="list-item-text"> {{ item[0] }} - {{ item[1] }} </span>
          </li>
          <li>
            <span name="list-item-text" @click="loadMaxList">
              Showing {{ suggestions.length }} of {{ totalResults }} total.
              <span v-if="suggestions.length !== totalResults">Click to load more</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import axios from "axios";
import debounce from "lodash.debounce";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["icd10-selected"]);

const minTermLen = 3;
const typedText = ref("");
const loading = ref(false);
const isSearchDropdownVisible = ref(false);
const selectedTerm = ref(null);
const suggestions = ref([]);
const totalResults = ref(0);

const inputTooShort = computed(() => typedText.value.length < minTermLen);
const icd10CodeServiceQueryURL = "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&df=code,name";

watch(
  typedText,
  debounce(() => reloadSuggestions(false), 500)
);

async function reloadSuggestions(loadFullResults) {
  const newText = typedText.value;

  if (newText) {
    isSearchDropdownVisible.value = true;

    if (!inputTooShort.value) {
      loading.value = true;
      const fullResults = loadFullResults ? "&maxList" : "";
      const url = icd10CodeServiceQueryURL + fullResults + "&terms=" + newText;
      const result = await axios.get(url).then((res) => res.data);
      suggestions.value = result[3].map((item) => [item[0], item[1]]);
      totalResults.value = result[0];
      loading.value = false;
    }
  } else {
    suggestions.value = [];
    isSearchDropdownVisible.value = false;
  }
}

function loadMaxList() {
  reloadSuggestions(true);
}

const cleanInputAndHideDropdown = () => {
  typedText.value = "";
  isSearchDropdownVisible.value = false;
  suggestions.value = [];
};

const selectICD10Code = (item) => {
  emit("icd10-selected", { diagnosis: item[1], icd10Code: item[0] });
  cleanInputAndHideDropdown();
  selectedTerm.value = true;
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
