<template>
  <PriorHeader />
  <div id="insurance-navigator-wrapper" class="insurance-navigator-wrapper">
    <h1 :class="{ hide: screenWidth < 835 }">Do Prior Authorizations</h1>
    <div class="insurance-navigator-main-block">
      <div class="insurance-navigator-img">
        <img src="../assets/images/woman-with-stethoscope.png" alt="doctor" />
        <div class="shadow-ellipse"></div>
      </div>
      <div :class="{ hide: screenWidth > 835 }" class="h1-wrapper">
        <h1>Prepare Prior Authorization For <span class="blue-text">Approval</span></h1>
      </div>
      <div class="insurance-navigator-form-wrapper">
        <div class="shadow-ellipse"></div>
        <div class="home-form">
          <div>
            <input id="findForm" v-model="formOption" type="radio" name="searchFormOption" value="findForm" />
            <label for="findForm" class="option-title">Option 1: Enter insurance and medication information</label>
            <hr />
            <GreenCirclePreloader v-if="!availableSearchOptions" />
            <form
              v-if="availableSearchOptions"
              :class="{ inactive: formOption !== 'findForm' }"
              @submit.prevent="getPriorAuthRequirements">
              <div class="insurance-plan-number">
                <div v-if="searchFormData.insuranceProvider.length" class="grid-row">
                  <label for="insurance-provider">Insurance Provider*</label>
                  <input
                    id="insurance-provider"
                    v-model="searchFormData.insuranceProvider"
                    type="text"
                    placeholder="e.g. Aetna"
                    :disabled="formOption !== 'findForm'"
                    @keyup="(event) => sendFormByEnterClicking(event, getPriorAuthRequirements)" />
                </div>
                <div v-else>
                  <SearchAutoComplete
                    :disabled="formOption !== 'findForm'"
                    :data="insuranceProviderSearchBox"
                    :required="true"
                    :allow-free-text-input="true"
                    @item-selected="selectedInsuranceProvider = $event" />
                </div>
                <span
                  v-if="!isInsuranceProviderValid && formButtonClicked && formOption === 'findForm'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <div class="insurance-state">
                <label for="insurance-state">Patient Insurance State*</label>
                <select
                  id="insurance-state"
                  v-model="searchFormData.insuranceCoverageState"
                  class="custom-select-arrow"
                  :class="{ 'custom-select-arrow-grey': formOption !== 'findForm' }"
                  :disabled="formOption !== 'findForm'"
                  @keyup="(event) => sendFormByEnterClicking(event, getPriorAuthRequirements)">
                  <option disabled value="">Select State</option>
                  <option v-for="state in states" :key="state">{{ state }}</option>
                </select>
                <span
                  v-if="!isInsuranceCoverageStateValid && formButtonClicked && formOption === 'findForm'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <div class="insurance-medication-name">
                <div v-if="searchFormData.medication.length" class="grid-row">
                  <label for="medication-name">Medication Name*</label>
                  <input
                    id="medication-name"
                    v-model="searchFormData.medication"
                    type="text"
                    :disabled="formOption !== 'findForm'"
                    placeholder="e.g. Monoferric (ferric derisomaltose)"
                    @keyup="(event) => sendFormByEnterClicking(event, getPriorAuthRequirements)" />
                </div>
                <div v-else>
                  <SearchAutoComplete
                    :disabled="formOption !== 'findForm'"
                    :data="medicationSearchBox"
                    :required="true"
                    :allow-free-text-input="true"
                    @item-selected="selectedMedication = $event" />
                </div>
                <span
                  v-if="!isMedicationValid && formButtonClicked && formOption === 'findForm'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <div class="insurance-medication-strength">
                <label for="insurance-state">Strength and Administration</label>
                <select
                  id="medication-strength"
                  v-model="searchFormData.strengthAndAdministration"
                  class="custom-select-arrow"
                  :class="{ 'custom-select-arrow-grey': formOption !== 'findForm' }"
                  :disabled="searchFormData.medication.length === 0 || formOption !== 'findForm'">
                  <option disabled value="">Select Strength</option>
                  <option
                    v-for="(option, index) in strengthAndAdministrationOptions[searchFormData.medication]"
                    :key="index">
                    {{ option.strength + ", " + option.routeAdministration }}
                  </option>
                </select>
                <span
                  v-if="!isStrengthAndAdministrationValid && formButtonClicked && formOption === 'findForm'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <button type="submit" :disabled="formOption !== 'findForm'">Find form</button>
            </form>
          </div>
          <div>
            <input
              id="enterKeyOption"
              v-model="formOption"
              type="radio"
              name="searchFormOption"
              value="enterKeyOption" />
            <label for="enterKeyOption" class="option-title">
              Option 2: Enter Key to access an initiated prior authorization
            </label>
            <hr />
            <form :class="{ inactive: formOption !== 'enterKeyOption' }" @submit.prevent="loadInitiatedPriorAuth">
              <div class="insurance-plan-number">
                <div class="grid-row">
                  <label for="prior-auth-key-code">Key Code*</label>
                  <input
                    id="prior-auth-key-code"
                    v-model="keyOptionSearch.orderKey"
                    type="text"
                    placeholder="e.g. XimwpJG9"
                    :disabled="formOption !== 'enterKeyOption'"
                    @keyup="(event) => sendFormByEnterClicking(event, loadInitiatedPriorAuth)" />
                </div>
                <span
                  v-if="!isOrderKeyValid && formButtonClicked && formOption === 'enterKeyOption'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <div class="insurance-state">
                <label for="patient-dob">Patient Date of Birth*</label>
                <input
                  id="patient-dob"
                  v-model="keyOptionSearch.dob"
                  type="date"
                  placeholder=""
                  :disabled="formOption !== 'enterKeyOption'"
                  @keyup="(event) => sendFormByEnterClicking(event, loadInitiatedPriorAuth)" />
                <span
                  v-if="!isDateValid && formButtonClicked && formOption === 'enterKeyOption'"
                  class="input-error-notification">
                  Please enter ALL fields to search.
                </span>
              </div>
              <button type="submit" :disabled="formOption !== 'enterKeyOption'">Review Prior Authorization</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="searchResultsBlock"></div>
    <GreenCirclePreloader v-if="preloader" />

    <div id="coverage" class="coverage">
      <div v-if="coverageBlock">
        <div class="bold">Is the medication covered under pharmacy or medical benefits?</div>
        <div class="buttons-wrapper">
          <button
            class="active-selection-button"
            :class="{ 'inactive-selection-button': benefitType === 'Medical benefits' }"
            @click="setBenefitType('Pharmacy benefits')">
            Pharmacy Benefits
          </button>
          <button
            class="active-selection-button"
            :class="{ 'inactive-selection-button': benefitType === 'Pharmacy benefits' }"
            @click="setBenefitType('Medical benefits')">
            Medical Benefits
          </button>
        </div>
      </div>
      <div v-if="coverageBlock" class="coverage-block-with-border">
        <div v-if="requirementsData.isSponsored" class="request-text">
          <div class="request-text">
            <span class="bold">
              {{ titleCaseString(searchFormData.insuranceProvider) }} {{ searchFormData.medication }} Benefit
              Investigation Request
            </span>
            <div class="coverage-btn-wrapper">
              <button class="btn-blue" @click="goToBenefitVerification()">Start Benefit Investigation</button>
            </div>
            <hr class="light-hr" />
          </div>
        </div>
        <div v-if="requirementsData.insurancePlanTypes && requirementsData.insurancePlanTypes.length > 0">
          <div
            v-for="planType in requirementsData.insurancePlanTypes.filter((planType) =>
              planType.benefitType ? (planType.benefitType === benefitType ? true : false) : true
            )"
            :key="planType"
            class="request-text">
            <span class="bold">
              {{ titleCaseString(searchFormData.insuranceProvider) }} {{ planType.insurancePlanType }} Drug Coverage
              Determination Form
            </span>
            <div class="coverage-btn-wrapper">
              <button class="btn-blue" @click="goToInteractiveForm(planType.insurancePlanType)">
                Start PA Request
              </button>
            </div>
            <hr class="light-hr" />
          </div>
        </div>
        <div v-if="benefitType === 'Pharmacy benefits'" class="request-text">
          <span class="bold"> Enter BIN, PCN Number and Plan Name to Find Prior Authorization Form </span>
          <span v-if="showMissingPlanDetailsForm" class="form">
            <div class="fields-in-row">
              <div class="input-elements-group half-form-container-width">
                <label for="bin">Rx BIN Number*</label>
                <input id="bin" v-model="missingPlanDetails.bin" type="text" placeholder="BIN" />
                <div v-if="!isBinValid && missingPlanFormButtonClicked" class="input-error-notification">
                  Please enter BIN number. Must be 6 digits.
                </div>
              </div>
              <div class="input-elements-group half-form-container-width">
                <label for="processor-control-number">Processor Control Number (Rx PCN)*</label>
                <input
                  id="processor-control-number"
                  v-model="missingPlanDetails.processorControlNumber"
                  type="text"
                  placeholder="PCN"
                  class="coverage-input" />
                <div v-if="!isPCNValid && missingPlanFormButtonClicked" class="input-error-notification">
                  Please enter a valid Processor Control Number (Rx PCN).
                </div>
              </div>
            </div>
            <div class="fields-in-row">
              <div class="input-elements-group half-form-container-width">
                <label for="plan-type">Plan Name</label>
                <input
                  id="plan-type"
                  v-model="missingPlanDetails.planType"
                  type="text"
                  placeholder="Plan Name"
                  class="coverage-input" />
              </div>
              <div class="input-elements-group half-form-container-width">
                <label for="grp-number">Group Number (Rx GRP)</label>
                <input
                  id="grp-number"
                  v-model="missingPlanDetails.grpNumber"
                  type="text"
                  placeholder="GRP"
                  class="coverage-input" />
              </div>
            </div>
          </span>
          <div v-if="showMissingPlanDetailsForm" class="coverage-btn-wrapper">
            <button class="btn-blue" @click="sendMissingPlanForm">Start PA Request</button>
          </div>
          <div v-else class="coverage-btn-wrapper">
            <button class="btn-blue" @click="showMissingPlanDetailsForm = true">Add Plan Details</button>
          </div>
        </div>
      </div>
    </div>
    <div class="errors-container">
      <span v-if="error" class="input-error-notification">
        Sorry, something went wrong. Please contact us at
        <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
      </span>
      <span v-if="specificError" class="input-error-notification">
        {{ specificError }}
      </span>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { onBeforeMount, ref, computed, watchEffect, watch } from "vue";
import { useRouter } from "vue-router";
import { mainServices } from "@/services/mainServices";
import { analyticsServices } from "@/services/analyticsService";
import { orderInfoService } from "@/services/orderInfoService";
import { usaStates } from "@/utils/usaStates";
import { storeToRefs } from "pinia";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import SearchAutoComplete from "@/components/SearchAutoComplete.vue";
import { useRequirementsStore } from "@/stores/requirementsStore";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { usePdfSubmissionStore } from "@/stores/pdfSubmissionStore";
import { FORM_VALIDATION_PATTERNS } from "@/utils/constants";
import {
  sendFormByEnterClicking,
  generateRandom4DigitNumber,
  clearOrderInfoStorage,
  clearPdfSubmissionStorage,
  titleCaseString,
} from "@/utils";

const { pdfSubmission } = storeToRefs(usePdfSubmissionStore());
const { orderInfo } = storeToRefs(useOrderInfoStore());
const { requirementsData } = storeToRefs(useRequirementsStore());

const searchFormData = ref({
  insuranceProvider: null,
  insuranceCoverageState: null,
  medication: null,
  strengthAndAdministration: null,
  medicationClass: null,
});

const keyOptionSearch = ref({
  orderKey: null,
  dob: null,
});

const missingPlanDetails = ref({
  bin: null,
  processorControlNumber: null,
  grpNumber: null,
  planType: null,
});

const error = ref(false);
const specificError = ref(null);
const screenWidth = ref(null);
const formButtonClicked = ref(false);
const showMissingPlanDetailsForm = ref(false);
const benefitType = ref("Pharmacy benefits");

function setBenefitType(type) {
  benefitType.value = type;
}

function displayWindowSize() {
  screenWidth.value = document.documentElement.clientWidth;
}

window.addEventListener("resize", displayWindowSize);

displayWindowSize();
// not used yet for auto-filling
const availableSearchOptions = ref(null);

const coverageBlock = ref(false);
const preloader = ref(false);

const router = useRouter();
const states = ref([]);

const selectedMedication = ref(null);
const medicationSearchBox = ref(null);
const formOption = ref("findForm");

const insuranceProviderSearchBox = ref(null);
const selectedInsuranceProvider = ref(null);

const missingPlanFormButtonClicked = ref(false);

onBeforeMount(() => {
  clearOrderInfoStorage();
  clearPdfSubmissionStorage();
  getAvailableSearchOptions();
  watchEffect(() => {
    if (availableSearchOptions.value) {
      medicationSearchBox.value = prepareSearchBox(
        "Medication Name",
        availableSearchOptions.value.medications,
        "medication"
      );
      insuranceProviderSearchBox.value = prepareSearchBox(
        "Insurance Provider",
        availableSearchOptions.value.insuranceProviders
      );
    }
  });
  clearTheForm();

  for (let stateData of Object.values(usaStates)) {
    states.value.push(stateData.name);
  }
});

function prepareSearchBox(label, data) {
  const searchBox = {
    options: [],
    label: label,
    searchId: generateRandom4DigitNumber(),
  };
  data.forEach((item) => {
    let processedItem = {};
    if (typeof item === "object") {
      processedItem = { ...item, searchText: `${item.medication.toLowerCase()}`, label: item.medication };
    } else if (typeof item === "string") {
      processedItem = { searchText: `${item.toLowerCase()}`, label: item };
    }
    searchBox.options.push(processedItem);
  });
  return searchBox;
}

watch(selectedMedication, (newSelectedMedication) => {
  if (newSelectedMedication) {
    searchFormData.value.medication = newSelectedMedication.label;
  }
});

watch(selectedInsuranceProvider, (newSelectedInsuranceProvider) => {
  if (newSelectedInsuranceProvider) {
    searchFormData.value.insuranceProvider = newSelectedInsuranceProvider.label;
  }
});

watch(
  searchFormData,
  () => {
    if (searchFormData.value.insuranceProvider.length === 0 && selectedInsuranceProvider.value) {
      selectedInsuranceProvider.value = null;
    }
    if (searchFormData.value.medication.length === 0 && selectedMedication.value) {
      selectedMedication.value = null;
    }
  },
  { deep: true }
);

async function getAvailableSearchOptions() {
  availableSearchOptions.value = await mainServices.availableSearchOptions();
}

// Validators
const isInsuranceProviderValid = computed(() => {
  const value = searchFormData.value.insuranceProvider;
  return value !== null && value.trim() !== "";
});

const isInsuranceCoverageStateValid = computed(() => {
  const value = searchFormData.value.insuranceCoverageState;
  return value !== null && value.trim() !== "";
});

const isMedicationValid = computed(() => {
  const value = searchFormData.value.medication;
  return value !== null && value.trim() !== "";
});

const isStrengthAndAdministrationValid = computed(() => {
  const value = searchFormData.value.strengthAndAdministration;
  if (searchFormData.value.medication && !strengthAndAdministrationOptions[searchFormData.value.medication]) {
    return true;
  } else {
    return value !== null && value.trim() !== "";
  }
});

const isBinValid = computed(() => {
  const value = missingPlanDetails.value.bin;
  return value !== null && value.trim() !== "" && FORM_VALIDATION_PATTERNS.BIN_NUMBER_PATTERN.test(value);
});

const isPCNValid = computed(() => {
  const value = missingPlanDetails.value.processorControlNumber;
  return value !== null && value.trim() !== "";
});

const isOrderKeyValid = computed(() => {
  const value = keyOptionSearch.value.orderKey;
  return value !== null && value.trim() !== "" && FORM_VALIDATION_PATTERNS.ORDER_KEY_PATTERN.test(value);
});

const isDateValid = computed(() => {
  const value = keyOptionSearch.value.dob;
  return value !== null && value.trim() !== "";
});

function assignMedicationClass() {
  if (availableSearchOptions.value) {
    const medicationsList = availableSearchOptions.value.medications;

    for (let i = 0; i < medicationsList.length; i++) {
      if (medicationsList[i].medication === searchFormData.value.medication) {
        searchFormData.value.medicationClass = medicationsList[i].medicationClass;
      }
    }
  }
}

const strengthAndAdministrationOptions = computed(() => {
  let options = {};
  if (availableSearchOptions.value) {
    const medicationsList = availableSearchOptions.value.medications;

    for (let i = 0; i < medicationsList.length; i++) {
      options[medicationsList[i].medication] = medicationsList[i].strengthAndAdministration;
    }
  }
  return options;
});

function sendMissingPlanForm() {
  missingPlanFormButtonClicked.value = true;
  if (isBinValid.value && isPCNValid.value) {
    const data = { ...missingPlanDetails.value };
    data.insuranceCoverageState = searchFormData.value.insuranceCoverageState;
    data.insuranceProvider = searchFormData.value.insuranceProvider;
    data.medication = searchFormData.value.medication;
    mainServices.sendMissingPlanDetails(data);
    goToInteractiveForm(missingPlanDetails.value.planType);
  }
}

async function loadInitiatedPriorAuth() {
  specificError.value = null;
  formButtonClicked.value = true;
  if (isOrderKeyValid.value && isDateValid.value) {
    preloader.value = true;
    const searchResultsBlock = document.getElementById("searchResultsBlock");
    searchResultsBlock.scrollIntoView({ behavior: "smooth" });
    try {
      error.value = false;
      coverageBlock.value = false;
      showMissingPlanDetailsForm.value = false;
      clearOrderInfoStorage();
      clearPdfSubmissionStorage();
      const searchResults = await orderInfoService.validateAccess(keyOptionSearch.value);
      if (searchResults.allowedAccess) {
        router.push({ name: "access-initiated-prior-auth", params: { id: keyOptionSearch.value.orderKey } });
        preloader.value = false;
        window.scrollTo(0, 0);
      } else {
        clearTheForm();
        preloader.value = false;
        specificError.value = "The key code or patient's date of birth you entered is invalid. Please try again.";
      }
    } catch (err) {
      clearTheForm();
      preloader.value = false;
      error.value = true;
    }
  }
}

async function getPriorAuthRequirements() {
  formButtonClicked.value = true;
  if (
    isInsuranceProviderValid.value &&
    isInsuranceCoverageStateValid.value &&
    isMedicationValid.value &&
    isInsuranceCoverageStateValid.value &&
    isStrengthAndAdministrationValid.value
  ) {
    preloader.value = true;
    const searchResultsBlock = document.getElementById("searchResultsBlock");
    searchResultsBlock.scrollIntoView({ behavior: "smooth" });
    try {
      error.value = false;
      coverageBlock.value = false;
      showMissingPlanDetailsForm.value = false;
      assignMedicationClass();
      clearOrderInfoStorage();
      clearPdfSubmissionStorage();
      const searchResults = await mainServices.searchPriorAuthForms(searchFormData.value);
      orderInfo.value.medication = searchFormData.value.medication;
      orderInfo.value.strength = searchFormData.value.strengthAndAdministration
        ? searchFormData.value.strengthAndAdministration.split(", ")[0]
        : "";
      orderInfo.value.routeAdministration = searchFormData.value.strengthAndAdministration
        ? searchFormData.value.strengthAndAdministration.split(", ")[1]
        : "";
      pdfSubmission.value.medication = searchFormData.value.medication;
      orderInfo.value.payerQuestions = searchResults.payerQuestions;
      requirementsData.value.dispensingInfoRequired =
        searchResults.dispensingInfoRequired && searchResults.dispensingInfoRequired.length > 0 ? true : false;
      requirementsData.value.diagnosisRequirements = searchResults.diagnosisRequirements;
      requirementsData.value.medicationRequirements = searchResults.medicationRequirements;
      requirementsData.value.stepTherapyRequirements = searchResults.stepTherapyRequirements;
      requirementsData.value.insurancePlanTypes = searchResults.insurancePlanTypes;
      requirementsData.value.isSponsored = searchResults.isSponsored;
      if (
        (requirementsData.value.insurancePlanTypes && !requirementsData.value.insurancePlanTypes.length) ||
        !requirementsData.value.insurancePlanTypes
      ) {
        showMissingPlanDetailsForm.value = true;
      }
      analyticsServices.logRequirementsSearchAction(searchFormData.value);
      preloader.value = false;
      coverageBlock.value = true;
    } catch (err) {
      clearTheForm();
      preloader.value = false;
      error.value = true;
    } finally {
      const coverageBlockView = document.getElementById("coverage");
      setTimeout(() => {
        coverageBlockView.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 0);
    }
  }
}

function addInsuranceDetailsToOrder(stores, planType) {
  for (let store of stores) {
    store.insuranceProvider = titleCaseString(searchFormData.value.insuranceProvider);
    store.patientInfo.state = searchFormData.value.insuranceCoverageState;
    store.insurancePlanType = planType && planType.length > 0 ? planType : "";
    store.benefitType = benefitType.value;
    store.bin = missingPlanDetails.value.bin;
    store.processorControlNumber = missingPlanDetails.value.processorControlNumber;
    store.grpNumber = missingPlanDetails.value.grpNumber;
  }
}

function goToBenefitVerification() {
  addInsuranceDetailsToOrder([orderInfo.value]);
  router.push({ name: "benefit-investigation" });
}

function goToInteractiveForm(planType) {
  addInsuranceDetailsToOrder([orderInfo.value], planType);
  router.push({ name: "check-my-coverage" });
}

function clearTheForm() {
  searchFormData.value.insuranceProvider = "";
  searchFormData.value.insuranceCoverageState = "";
  searchFormData.value.medication = "";
  searchFormData.value.strengthAndAdministration = "";
  searchFormData.value.medicationClass = "";
  selectedMedication.value = null;
  selectedInsuranceProvider.value = null;
  missingPlanDetails.value.bin = "";
  missingPlanDetails.value.processorControlNumber = "";
  missingPlanDetails.value.grpNumber = "";
  missingPlanDetails.value.planType = "";
  formButtonClicked.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_insurance-navigator-page.scss";
</style>
