<template>
  <div class="form">
    <div class="light-background-section">
      <div class="terms-and-conditions">
        Healthcare provider release:* By signing below, I hereby certify that: (a) I am a licensed practitioner, in a
        good standing under applicable law; (b) in my medical judgement, I have determined that the product being
        prescribed is to treat a diagnosis(es) consistent with indications, dosing and appropriate uses as described in
        the product’s prescribing information; (c) the information I have provided on this enrollment form is, to the
        best of my knowledge, true, complete, and accurate in all respects; and (d) I have obtained the necessary
        authorization from the patient, or where appropriate the patient’s parent, caregiver, and/or legal
        representative to use, disclose, share, and/or release the above-referenced information along with other
        protected health information (as defined in the Health Insurance Portability and Accountability Act of 1996
        (HIPAA) for the sole purpose of providing patient assistance. I will immediately notify Quantification by Design
        Inc. (dba ”Lamar Health”) if the above-named patient, or where appropriate the patient’s parent , caregiver,
        and/or legal representative, revokes their consent to share their PHI with Lamar Health. I give you permission
        to contact me, or the above named patient/caregiver, with any questions related to Lamar Health.
      </div>
      <canvas id="signature-pad" class="signature"></canvas>
      <span
        v-if="props.formButtonClicked && (!props.signature || (props.signature && !props.signature.length))"
        class="input-error-notification">
        Please provide your signature
      </span>
      <div class="in-form-half-width-buttons-wrapper">
        <button class="return-button" @click="clearSignature">Reset</button>
        <button class="save-button" :class="{ 'return-button': saveButtonText === 'Saved' }" @click="saveSignature">
          {{ saveButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import SignaturePad from "signature_pad";

const signaturePad = ref(null);
const saveButtonText = ref("Save");
const emit = defineEmits(["updateSignature"]);

const props = defineProps({
  signature: {
    type: String,
    default() {
      return "";
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

onMounted(() => {
  const canvas = document.querySelector("canvas");
  signaturePad.value = new SignaturePad(canvas, {
    backgroundColor: "rgb(255, 255, 255)",
    penColor: "rgb(0, 0, 0)",
  });
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  if (props.signature) {
    signaturePad.value.fromDataURL(props.signature);
    saveButtonText.value = "Saved";
  } else {
    signaturePad.value.clear();
    emit("updateSignature", "");
  }
});

const clearSignature = () => {
  signaturePad.value.clear();
  emit("updateSignature", "");
  saveButtonText.value = "Save";
};

const saveSignature = () => {
  if (!signaturePad.value.isEmpty()) {
    emit("updateSignature", signaturePad.value.toDataURL());
    saveButtonText.value = "Saved";
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
