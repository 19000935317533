<template>
  <div class="patient-information-form-wrapper">
    <div v-if="orderInfoPreview" class="form">
      <div v-if="props.isEditable && props.dispensingInfoRequired" class="patient-information-part">
        <span class="header-with-select-box">
          <h5>Dispensing Provider Information</h5>
          <select
            v-if="props.isEditable"
            id="provider-select"
            v-model="dispensingProviderSelection"
            class="custom-select-arrow">
            <option disabled value="null">Select Dispensing Provider</option>
            <option
              v-for="provider in props.dispensingProviderAddressBook.filter(
                (provider) => provider.type && provider.name && provider.pin
              )"
              :key="provider.id">
              {{ provider.type }} {{ provider.name }}, {{ provider.pin }}
            </option>
          </select>
        </span>
        <hr class="bold-hr" />

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <span
              v-if="
                orderInfoPreview.dispensingProviderInfo.type &&
                dispensingProviderTypes.includes(orderInfoPreview.dispensingProviderInfo.type)
              ">
              <label for="selected-dispensing-provider">Select Dispensing Provider/Pharmacy*</label>
              <select
                id="selected-dispensing-provider"
                v-model="orderInfoPreview.dispensingProviderInfo.type"
                class="custom-select-arrow"
                @change="emit('updateDispensingProviderInfo', 'type', orderInfoPreview.dispensingProviderInfo.type)">
                <option disabled value="null">Select Dispensing Provider or Pharmacy</option>
                <option v-for="providerType in dispensingProviderTypes" :key="providerType">{{ providerType }}</option>
              </select>
            </span>
            <span
              v-else-if="
                orderInfoPreview.dispensingProviderInfo.type &&
                !dispensingProviderTypes.includes(orderInfoPreview.dispensingProviderInfo.type)
              ">
              <label for="other-dispensing-provider">Other Site of Service*</label>
              <input
                id="other-dispensing-provider"
                v-model="otherDispensingProviderType"
                type="text"
                placeholder=""
                :readonly="!props.isEditable"
                @change="emit('updateDispensingProviderInfo', 'type', otherDispensingProviderType)" />
            </span>
            <span v-else>
              <label for="select-dispensing-provider">Select Dispensing Provider/Pharmacy*</label>
              <select
                id="select-dispensing-provider"
                v-model="orderInfoPreview.dispensingProviderInfo.type"
                class="custom-select-arrow"
                @change="emit('updateDispensingProviderInfo', 'type', orderInfoPreview.dispensingProviderInfo.type)">
                <option disabled value="null">Select Dispensing Provider or Pharmacy</option>
                <option v-for="providerType in dispensingProviderTypes" :key="providerType">{{ providerType }}</option>
              </select>
            </span>
          </div>
          <div class="input-elements-group half-form-container-width">
            <div
              v-if="
                orderInfoPreview.dispensingProviderInfo.type && orderInfoPreview.dispensingProviderInfo.type === 'Other'
              ">
              <label for="other-dispensing-type-specified">Please Specify Site of Service*</label>
              <input
                id="other-dispensing-type-specified"
                v-model="otherDispensingProviderType"
                type="text"
                placeholder=""
                :readonly="!props.isEditable"
                @change="emit('updateDispensingProviderInfo', 'type', otherDispensingProviderType)" />
            </div>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-name center-name">Pharmacy or Provider Name*</label>
            <input
              id="dispensing-provider-name"
              v-model="orderInfoPreview.dispensingProviderInfo.name"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDispensingProviderInfo', 'name', orderInfoPreview.dispensingProviderInfo.name)" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-address">Address*</label>
            <input
              id="dispensing-provider-address"
              v-model="orderInfoPreview.dispensingProviderInfo.address"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateDispensingProviderInfo', 'address', orderInfoPreview.dispensingProviderInfo.address)
              " />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-phone">Phone*</label>
            <input
              id="dispensing-provider-phone"
              v-model="orderInfoPreview.dispensingProviderInfo.phone"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDispensingProviderInfo', 'phone', orderInfoPreview.dispensingProviderInfo.phone)" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-fax">Fax*</label>
            <input
              id="dispensing-provider-fax"
              v-model="orderInfoPreview.dispensingProviderInfo.fax"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDispensingProviderInfo', 'fax', orderInfoPreview.dispensingProviderInfo.fax)" />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-tin">TIN*</label>
            <input
              id="dispensing-provider-tin"
              v-model="orderInfoPreview.dispensingProviderInfo.tin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDispensingProviderInfo', 'tin', orderInfoPreview.dispensingProviderInfo.tin)" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-pin">PIN*</label>
            <input
              id="dispensing-provider-pin"
              v-model="orderInfoPreview.dispensingProviderInfo.pin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDispensingProviderInfo', 'pin', orderInfoPreview.dispensingProviderInfo.pin)" />
          </div>
        </div>
        <hr class="bold-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="place-of-administration">Place of Administration*</label>
            <select
              id="place-of-administration"
              v-model="orderInfoPreview.placeOfAdministration"
              class="custom-select-arrow"
              @change="emit('updateOrderInfoField', 'placeOfAdministration', orderInfoPreview.placeOfAdministration)">
              <option disabled value="null">Select Place of Administration</option>
              <option v-for="place in placeOfAdministrationOptions" :key="place">{{ place }}</option>
            </select>
          </div>
        </div>

        <div
          v-if="
            orderInfoPreview.placeOfAdministration &&
            (orderInfoPreview.placeOfAdministration === 'Outpatient Infusion Center' ||
              orderInfoPreview.placeOfAdministration === 'Home Infusion Center')
          ">
          <div class="fields-in-row">
            <div class="input-elements-group half-form-container-width">
              <label for="outpatient-infusion-phone">Phone*</label>
              <input
                id="outpatient-infusion-phone"
                v-model="orderInfoPreview.placeOfAdministrationDetails.phone"
                type="text"
                placeholder=""
                :readonly="!props.isEditable"
                @change="
                  emit(
                    'updatePlaceOfAdministrationDetails',
                    'phone',
                    orderInfoPreview.placeOfAdministrationDetails.phone
                  )
                " />
            </div>
            <div class="input-elements-group half-form-container-width">
              <label for="outpatient-name">
                <span v-if="orderInfoPreview.placeOfAdministration === 'Outpatient Infusion Center'">Center Name*</span>
                <span v-if="orderInfoPreview.placeOfAdministration === 'Home Infusion Center'">Agency Name*</span>
              </label>
              <input
                id="outpatient-name"
                v-model="orderInfoPreview.placeOfAdministrationDetails.name"
                type="text"
                placeholder=""
                :readonly="!props.isEditable"
                @change="
                  emit('updatePlaceOfAdministrationDetails', 'name', orderInfoPreview.placeOfAdministrationDetails.name)
                " />
            </div>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="administration-codes">
              Administration Code(s) <small>(CPT/HCPCS/J-Code & Modifier)</small>*
            </label>
            <input
              id="administration-codes"
              v-model="orderInfoPreview.administrationCodesCpt"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateOrderInfoField', 'administrationCodesCpt', orderInfoPreview.administrationCodesCpt)
              " />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="poa-address">Address*</label>
            <input
              id="poa-address"
              v-model="orderInfoPreview.placeOfAdministrationAddress"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit(
                  'updateOrderInfoField',
                  'placeOfAdministrationAddress',
                  orderInfoPreview.placeOfAdministrationAddress
                )
              " />
          </div>
        </div>
      </div>
      <div
        v-if="
          !props.isEditable && orderInfoPreview.dispensingProviderInfo && orderInfoPreview.dispensingProviderInfo.name
        "
        class="patient-information-part">
        <h5>Dispensing Provider Information</h5>
        <hr class="bold-hr" />

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-type">Select Dispensing Provider/Pharmacy*</label>
            <input
              id="dispensing-provider-type"
              v-model="orderInfoPreview.dispensingProviderInfo.type"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-name-1 center-name">Pharmacy or Provider Name*</label>
            <input
              id="dispensing-provider-name-1"
              v-model="orderInfoPreview.dispensingProviderInfo.name"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-address-1">Address*</label>
            <input
              id="dispensing-provider-address-1"
              v-model="orderInfoPreview.dispensingProviderInfo.address"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-phone-1">Phone*</label>
            <input
              id="dispensing-provider-phone-1"
              v-model="orderInfoPreview.dispensingProviderInfo.phone"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-fax-1">Fax*</label>
            <input
              id="dispensing-provider-fax-1"
              v-model="orderInfoPreview.dispensingProviderInfo.fax"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-tin-1">TIN*</label>
            <input
              id="dispensing-provider-tin-1"
              v-model="orderInfoPreview.dispensingProviderInfo.tin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="dispensing-provider-pin-1">PIN*</label>
            <input
              id="dispensing-provider-pin-1"
              v-model="orderInfoPreview.dispensingProviderInfo.pin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>
        <hr class="bold-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="place-of-administration-1">Place of Administration*</label>
            <input
              id="place-of-administration-1"
              v-model="orderInfoPreview.placeOfAdministration"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>

        <div
          v-if="
            orderInfoPreview.placeOfAdministration &&
            (orderInfoPreview.placeOfAdministration === 'Outpatient Infusion Center' ||
              orderInfoPreview.placeOfAdministration === 'Home Infusion Center')
          ">
          <div class="fields-in-row">
            <div class="input-elements-group half-form-container-width">
              <label for="outpatient-infusion-phone-1">Phone*</label>
              <input
                id="outpatient-infusion-phone-1"
                v-model="orderInfoPreview.placeOfAdministrationDetails.phone"
                type="text"
                placeholder=""
                :readonly="!props.isEditable" />
            </div>
            <div class="input-elements-group half-form-container-width">
              <label for="outpatient-name-1">
                <span v-if="orderInfoPreview.placeOfAdministration === 'Outpatient Infusion Center'">Center Name*</span>
                <span v-if="orderInfoPreview.placeOfAdministration === 'Home Infusion Center'">Agency Name*</span>
              </label>
              <input
                id="outpatient-name-1"
                v-model="orderInfoPreview.placeOfAdministrationDetails.name"
                type="text"
                placeholder=""
                :readonly="!props.isEditable" />
            </div>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group half-form-container-width">
            <label for="administration-codes-1">
              Administration Code(s) <small>(CPT/HCPCS/J-Code & Modifier)</small>*
            </label>
            <input
              id="administration-codes-1"
              v-model="orderInfoPreview.administrationCodesCpt"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
          <div class="input-elements-group half-form-container-width">
            <label for="poa-address-1">Address*</label>
            <input
              id="poa-address-1"
              v-model="orderInfoPreview.placeOfAdministrationAddress"
              type="text"
              placeholder=""
              :readonly="!props.isEditable" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const placeOfAdministrationOptions = [
  "Outpatient Infusion Center",
  "Home Infusion Center",
  "Self-Administered",
  "Physician Office",
];
const dispensingProviderTypes = ["Physician's Office", "Retail Pharmacy", "Specialty Pharmacy", "Other"];
const dispensingProviderSelection = ref(null);
const otherDispensingProviderType = ref(null);
const emit = defineEmits([
  "updateDispensingProviderInfo",
  "updatePlaceOfAdministrationDetails",
  "updateOrderInfoField",
  "setDispensingProviderInfoFromAddressBook",
]);

const props = defineProps({
  orderInfo: {
    type: Object,
    required: true,
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  dispensingInfoRequired: {
    type: Boolean,
    default() {
      return false;
    },
  },
  dispensingProviderAddressBook: {
    type: Array,
    default() {
      return [];
    },
  },
});

const orderInfoPreview = ref(props.orderInfo);

watch(dispensingProviderSelection, (newVal) => {
  if (newVal) {
    const selectedProvider = props.dispensingProviderAddressBook.find(
      (provider) => `${provider.type} ${provider.name}, ${provider.pin}` === newVal
    );
    if (selectedProvider.id) {
      emit("setDispensingProviderInfoFromAddressBook", selectedProvider, selectedProvider.id);
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
