<template>
  <div>
    <div v-if="pharmacySelection && pharmacySelection.description">
      <div class="input-elements-group">
        <label for="pharmacy-selected">Pharmacy Info</label>
        <input
          id="pharmacy-selected"
          v-model="pharmacySelection.description"
          :readonly="!props.isEditable"
          type="text"
          placeholder="" />
      </div>
    </div>
    <div v-else>
      <form v-if="props.isEditable" @submit.prevent="loadPharmacyOptions">
        <div class="input-elements-group" :class="{ expanded: isSearchDropdownVisible }">
          <label for="search-pharmacy"> Pharmacy Info </label>
          <input
            id="search-pharmacy"
            v-model="typedText"
            type="text"
            placeholder="Please input pharmacy name and zip code"
            @keyup.esc="cleanInputAndHideDropdown" />
        </div>
        <span v-if="!isZipCodeValid && formButtonClicked" class="input-error-notification">
          Please include a zip code in your search
        </span>
        <span v-if="!isPharmacyNameValid && formButtonClicked" class="input-error-notification">
          Please include a name of the pharmacy in your search (at least 2 letters)
        </span>
        <div v-if="isSearchDropdownVisible" class="search-dropdown-block">
          <div v-if="loading">
            <ul>
              <li>
                <span name="list-item-text">Loading...</span>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul>
              <li v-for="pharmacy in pharmaciesByNames" :key="pharmacy.npiNumber" @click="selectPharmacy(pharmacy)">
                <span name="list-item-text">
                  <span v-if="hasOtherNames(pharmacy)">
                    {{ JSON.parse(pharmacy.otherNames.replace(/'/g, '"'))[0] }}
                  </span>
                  <span v-else>{{ pharmacy.organizationName }}</span>
                  {{ pharmacy.address1 }} {{ pharmacy.city }} {{ pharmacy.state }}
                  {{ pharmacy.postalCode.substring(0, 5) }}
                </span>
              </li>
              <li @click="loadNearbyPharmacyOptions">
                <span name="list-item-text">Click here to load pharmacies from nearby locations</span>
              </li>
            </ul>
          </div>
        </div>
      </form>
      <span v-if="!isPharmacyValid && props.formButtonClicked" class="input-error-notification">
        Please enter pharmacy name and zip code and select a pharmacy.
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { mainServices } from "@/services/mainServices";

const emit = defineEmits(["pharmacy-selected"]);

const props = defineProps({
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
  pharmacySelection: {
    type: Object,
    default() {
      return {
        description: "",
      };
    },
  },
});

const typedText = ref("");
const loading = ref(false);
const isSearchDropdownVisible = ref(false);
const pharmaciesByNames = ref([]);

const totalResults = ref(0);
const pharmacySelection = ref(props.pharmacySelection ? props.pharmacySelection : { description: "" });

const formButtonClicked = ref(false);
const zipCodeInput = ref("");
const pharmacyNameInput = ref("");

const isZipCodeValid = computed(() => {
  const value = zipCodeInput.value;
  return value !== null && value.length === 5;
});

const isPharmacyNameValid = computed(() => {
  const value = pharmacyNameInput.value;
  return value !== null && value.length > 2;
});

const isPharmacyValid = computed(() => {
  if (props.pharmacySelection) {
    return props.pharmacySelection.description.length > 0;
  } else {
    return false;
  }
});

watch(typedText, () => {
  if (typedText.value) {
    zipCodeInput.value = typedText.value.match(/\d{5}/g) ? typedText.value.match(/\d{5}/g)[0] : "";
    pharmacyNameInput.value =
      typedText.value.replace(/\d/g, "").trim().length > 2 ? typedText.value.replace(/\d/g, "").trim() : "";
  } else {
    cleanInputAndHideDropdown();
  }
});

async function loadNearbyPharmacyOptions() {
  loading.value = true;
  const result = await mainServices.getNearestPharmacies({
    zipCode: zipCodeInput.value,
    pharmacyName: pharmacyNameInput.value,
  });
  totalResults.value = result.length;
  pharmaciesByNames.value = [...result];
  loading.value = false;
}

async function loadPharmacyOptions() {
  pharmaciesByNames.value = [];
  formButtonClicked.value = true;
  if (isPharmacyNameValid.value && isZipCodeValid.value) {
    isSearchDropdownVisible.value = true;
    loading.value = true;
    const result = await mainServices.searchPharmacies({
      zipCode: zipCodeInput.value,
      pharmacyName: pharmacyNameInput.value,
    });
    totalResults.value = result.length;
    pharmaciesByNames.value = [...result];
    loading.value = false;
  } else {
    pharmaciesByNames.value = [];
    isSearchDropdownVisible.value = false;
  }
}

const cleanInputAndHideDropdown = () => {
  typedText.value = "";
  formButtonClicked.value = false;
  zipCodeInput.value = "";
  pharmacyNameInput.value = "";
  isSearchDropdownVisible.value = false;
  pharmaciesByNames.value = [];
};

const selectPharmacy = (item) => {
  pharmacySelection.value = item;
  pharmacySelection.value.description = `${item.organizationName} ${item.address1} ${item.city} ${
    item.state
  } ${item.postalCode.substring(0, 5)}`;
  emit("pharmacy-selected", pharmacySelection.value);
  cleanInputAndHideDropdown();
};

const hasOtherNames = (pharmacy) => {
  if (pharmacy && pharmacy.otherNames) {
    return JSON.parse(pharmacy.otherNames.replace(/'/g, '"')).length > 0;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
