<template>
  <div class="smart-engine-success-wrapper">
    <div class="smart-engine-success-img">
      <img src="@/assets/images/modal-green-checkmark.svg" alt="success" />
    </div>
    <h2>{{ props.title }}</h2>
    <p>{{ props.message }}</p>
    <p>You May close or Move from this page.</p>
    <div class="smart-engine-start-new-patient">
      <button @click="redirectToHomePage">Start New Patient</button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
  title: {
    type: String,
    default() {
      return "Success";
    },
  },
  message: {
    type: String,
    default() {
      return "We are on it!";
    },
  },
});

const router = useRouter();
function redirectToHomePage() {
  router.push({ name: "insurance-navigator-page" });
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_smart-engine-container.scss";
</style>
