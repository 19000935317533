<template>
  <div class="wrapper">
    <div class="main-circle">
      <div class="green-circle">
        <div class="brown-circle"></div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "@/styles/components/_green-circle-preloader";
</style>
