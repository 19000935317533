<template>
  <footer>
    <div class="left-footer">
      <span>©{{ currentYear }} dopriorauth.com </span>
    </div>
    <div class="center-footer">
      <small>{{ appVersion }}</small>
    </div>
    <div class="right-footer">
      <ul>
        <li>Terms and Conditions</li>
        <li>·</li>
        <li>Privacy Policy</li>
      </ul>
    </div>
  </footer>
</template>

<script setup>
const appVersion = process.env.VUE_APP_VERSION;
const currentYear = new Date().getFullYear();
</script>

<style lang="scss" scoped>
@import "@/styles/components/_prior-footer.scss";
</style>
