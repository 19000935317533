<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div v-if="props.isEditable || (drugsTried && drugsTried.length)" class="patient-information-part">
        <h5>Patient Drug History</h5>
        <hr class="bold-hr" />
        <div v-for="i in drugsTried.length" :key="i" class="fields-in-row">
          <div v-if="props.isEditable || drugsTried[i - 1].drugTried" class="input-elements-group drug-tried">
            <label :for="`drug-tried-${i}`">Drug Tried</label>
            <input
              :id="`drug-tried-${i}`"
              v-model="drugsTried[i - 1].drugTried"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDrugsTried', i - 1, 'drugTried', drugsTried[i - 1].drugTried)" />
          </div>
          <div v-if="props.isEditable || drugsTried[i - 1].startDate" class="input-elements-group start-date-trial">
            <label :for="`start-date-trial-${i}`">Start Date Tried</label>
            <input
              :id="`start-date-trial-${i}`"
              v-model="drugsTried[i - 1].startDate"
              type="date"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDrugsTried', i - 1, 'startDate', drugsTried[i - 1].startDate)" />
          </div>
          <div v-if="props.isEditable || drugsTried[i - 1].endDate" class="input-elements-group start-date-trial">
            <label :for="`end-date-trial-${i}`">End Date Tried</label>
            <input
              :id="`end-date-trial-${i}`"
              v-model="drugsTried[i - 1].endDate"
              type="date"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDrugsTried', i - 1, 'endDate', drugsTried[i - 1].endDate)" />
          </div>
          <div
            v-if="props.isEditable || drugsTried[i - 1].resultOfTrial"
            class="input-elements-group result-of-previous-drug-trial">
            <label :for="`result-of-previous-drug-trial-${i}`">Result of Previous Drug Trial</label>
            <input
              :id="`result-of-previous-drug-trial-${i}`"
              v-model="drugsTried[i - 1].resultOfTrial"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateDrugsTried', i - 1, 'resultOfTrial', drugsTried[i - 1].resultOfTrial)" />
          </div>
        </div>
        <div v-if="props.isEditable" class="in-form-button-wrapper">
          <button @click="addDrugTried">+ Drug Tried</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  drugsTried: {
    type: Array,
    required: true,
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const drugsTried = ref(props.drugsTried);

const emit = defineEmits(["updateDrugsTried"]);

function addDrugTried() {
  drugsTried.value.push({
    drugTried: null,
    startDate: null,
    endDate: null,
    resultOfTrial: null,
  });
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
