<template>
  <div v-if="healthCheck">
    <p>Health Check Completed</p>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { analyticsServices } from "@/services/analyticsService";

const healthCheck = ref(null);

onBeforeMount(async () => {
  healthCheck.value = await analyticsServices.healthCheck();
});
</script>
