<template>
  <div v-if="payerQuestions.length" class="patient-information-form-wrapper">
    <div class="form">
      <div class="light-background-section">
        <h5>Insurance Coverage Criteria</h5>
        <hr class="light-hr" />
        <div
          v-for="currentRequirement in payerQuestions"
          :key="currentRequirement.requirementRuleName"
          :class="{ hide: !isRequirementApplicable(currentRequirement) }">
          <p class="accented-paragraph">
            {{ currentRequirement.label }}
          </p>
          <div v-if="currentRequirement.requirementOptions[0].nodeType === 'radio'">
            <select
              :id="currentRequirement.requirementRuleName"
              v-model="currentRequirement.selectedOption"
              class="custom-select-arrow"
              @change="setSessionRequirement(currentRequirement)">
              <option
                v-for="option in currentRequirement.requirementOptions"
                :key="option.optionRuleName"
                :value="{
                  id: option.optionRuleName,
                  label: option.label,
                }">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div v-else-if="currentRequirement.requirementOptions[0].nodeType === 'textarea'">
            <textarea
              :id="`${currentRequirement.requirementOptions[0].optionRuleName}`"
              v-model="currentRequirement.selectedOption.label"
              class="textarea"
              rows="8"
              :placeholder="currentRequirement.requirementOptions[0].label"
              @change="setSessionRequirement(currentRequirement)"></textarea>
          </div>
          <div v-else>
            <input
              :id="`${currentRequirement.requirementOptions[0].optionRuleName}`"
              v-model="currentRequirement.selectedOption.label"
              :type="currentRequirement.requirementOptions[0].nodeType"
              :placeholder="currentRequirement.requirementOptions[0].label"
              @change="setSessionRequirement(currentRequirement)" />
          </div>
          <div v-if="currentRequirement.llmPrediction">
            <p class="llm-prediction">
              <span v-if="currentRequirement.llmPrediction.isHumanReviewRequested" style="color: #ff6347">
                Review Needed
              </span>
              <span v-if="currentRequirement.llmPrediction.context">
                Context: {{ currentRequirement.llmPrediction.context }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const payerQuestions = ref([]);
const sessionRequirements = ref([]);
const sessionRequirementOptions = ref([]);

const emit = defineEmits(["updateChecklist"]);

const props = defineProps({
  payerQuestions: {
    type: Array,
    default() {
      return [];
    },
  },
  checklist: {
    type: Array,
    default() {
      return [];
    },
  },
  llmPrediction: {
    type: Array,
    default() {
      return [];
    },
  },
});

onMounted(async () => {
  let payerQuestionsTemp = [];
  for (const requirement of props.payerQuestions) {
    let payerQuestion = requirement;
    const checklistSelection = props.checklist.find(
      (selection) => selection.id === requirement.requirementRuleName && selection.options[0].label
    );
    if (checklistSelection) {
      payerQuestion.selectedOption =
        checklistSelection.options.length > 1 ? checklistSelection.options : checklistSelection.options[0];
      sessionRequirements.value.push(requirement.requirementRuleName);
      sessionRequirementOptions.value.push(requirement.selectedOption.id);
      if (props.llmPrediction && props.llmPrediction.length > 0) {
        payerQuestion.llmPrediction = props.llmPrediction.find(
          (prediction) => prediction.answerId === requirement.requirementRuleName
        );
      }
    } else {
      if (payerQuestion.requirementOptions[0].nodeType === "radio") {
        payerQuestion.selectedOption = { id: null, label: null };
      }
      if (
        payerQuestion.requirementOptions[0].nodeType === "text" ||
        payerQuestion.requirementOptions[0].nodeType === "textarea" ||
        payerQuestion.requirementOptions[0].nodeType === "number" ||
        payerQuestion.requirementOptions[0].nodeType === "date"
      ) {
        payerQuestion.selectedOption = {
          id: payerQuestion.requirementOptions[0].optionRuleName,
          label: null,
        };
        payerQuestion.llmPrediction = null;
      }
    }
    payerQuestionsTemp.push(requirement);
  }
  payerQuestions.value.push(...sortRequirements(payerQuestionsTemp));
});

function sortRequirements(requirements) {
  const sortedRequirements = [];
  const requirementsWithRules = [];
  const requirementsWithNoRules = [];
  for (const requirement of requirements) {
    if (requirement.requirementRuleSet && requirement.requirementRuleSet.length > 0) {
      requirementsWithRules.push(requirement);
    } else {
      requirementsWithNoRules.push(requirement);
    }
  }
  requirementsWithRules.sort((a, b) => {
    if (a.requirementRuleSet.includes(b.requirementRuleName)) {
      return 1;
    } else if (b.requirementRuleSet.includes(a.requirementRuleName)) {
      return -1;
    } else {
      return 0;
    }
  });
  sortedRequirements.push(...requirementsWithNoRules);
  sortedRequirements.push(...requirementsWithRules);
  return sortedRequirements;
}

function setSessionRequirement(requirement) {
  if (!sessionRequirements.value.includes(requirement.requirementRuleName)) {
    sessionRequirements.value.push(requirement.requirementRuleName);
  }
  if (!sessionRequirementOptions.value.includes(requirement.selectedOption.id)) {
    if (requirement.requirementOptions[0].nodeType === "radio") {
      sessionRequirementOptions.value = sessionRequirementOptions.value.filter(
        (option) => !requirement.requirementOptions.map((option) => option.optionRuleName).includes(option)
      );
    }
    sessionRequirementOptions.value.push(requirement.selectedOption.id);
  }
  emit("updateChecklist", {
    id: requirement.requirementRuleName,
    label: requirement.label,
    options: [
      {
        id: requirement.selectedOption.id,
        label: requirement.selectedOption.label,
      },
    ],
  });
}

function isRequirementApplicable(requirement) {
  if (sessionRequirements.value.includes(requirement.requirementRuleName)) {
    return true;
  }
  if (!requirement.requirementRuleSet || requirement.requirementRuleSet.length === 0) {
    return true;
  }
  for (const rule of requirement.requirementRuleSet) {
    if (!sessionRequirements.value.includes(rule)) {
      return false;
    }
  }
  let optionsFailedRuleCheck = [];
  for (const option of requirement.requirementOptions) {
    for (const optionRule of option.optionRuleSet) {
      if (!sessionRequirementOptions.value.includes(optionRule)) {
        optionsFailedRuleCheck.push(option);
      }
    }
  }
  let tempRequirement = JSON.parse(JSON.stringify(requirement));
  for (const option of optionsFailedRuleCheck) {
    tempRequirement.requirementOptions.splice(
      tempRequirement.requirementOptions.findIndex((o) => o.optionRuleName === option.optionRuleName),
      1
    );
  }
  if (tempRequirement.requirementOptions.length === 0) {
    return false;
  } else {
    const electiveRequirementRuleSet = tempRequirement.electiveRequirementRuleSet;
    if (electiveRequirementRuleSet && electiveRequirementRuleSet.length > 0) {
      let electiveRequirements = [];
      for (const electiveRequirement of electiveRequirementRuleSet) {
        for (const rule of electiveRequirement) {
          if (sessionRequirements.value.includes(rule)) {
            electiveRequirements.push(electiveRequirement);
          }
        }
      }
      if (electiveRequirements.length === 0) {
        return false;
      }
      let electiveOptions = [];
      for (const option of tempRequirement.requirementOptions) {
        const electiveOptionRuleSet = option.electiveOptionRuleSet;
        if (electiveOptionRuleSet && electiveOptionRuleSet.length > 0) {
          for (const electiveOption of electiveOptionRuleSet) {
            for (const rule of electiveOption) {
              if (sessionRequirementOptions.value.includes(rule)) {
                electiveOptions.push(option);
              }
            }
          }
        }
      }
      if (electiveOptions.length === 0) {
        return false;
      }
      return true;
    }
    return true;
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
