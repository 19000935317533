<template>
  <div class="form">
    <div class="light-background-section">
      <div class="terms-and-conditions">
        Attestation: I confirm that the information provided within this Prior Authorization form is accurate and true.
        I understand that any person who knowingly makes or causes a false record or statement that is significant to a
        claim paid by the United States government or any state government may be subject to civil penalties under both
        the federal and state laws. I further confirm that I have reviewed the signature requirements within this Prior
        Authorization form. By signing this form, I represent that I am authorized to submit PAs on behalf of the NPI
        associated with this Prior Authorization.
      </div>
      <div class="terms-and-conditions">
        <input id="terms-and-conditions" v-model="acceptTermsOfService" type="checkbox" />
        <label for="terms-and-conditions"> I agree </label>
        <span v-if="props.formButtonClicked && !acceptTermsOfService" class="input-error-notification">
          Must confirm that you are authorized to submit Prior Authorizations on behalf of the NPI associated with this
          Prior Authorization
        </span>
      </div>
      <canvas id="signature-pad" class="signature"></canvas>
      <span
        v-if="props.formButtonClicked && (!props.signature || (props.signature && !props.signature.length))"
        class="input-error-notification">
        Please provide your signature
      </span>
      <div class="in-form-half-width-buttons-wrapper">
        <button class="return-button" @click="clearSignature">Reset</button>
        <button class="save-button" :class="{ 'return-button': saveButtonText === 'Saved' }" @click="saveSignature">
          {{ saveButtonText }}
        </button>
      </div>
      <div class="terms-and-conditions">
        Disclaimer: By providing my signature above, I acknowledge that my signature (1) will be electronically stored;
        and (2) may be reproduced by this Do Prior Auth user account and additionally, those user accounts linked to
        this NPI. Additionally, I expressly authorize such users to reproduce by signature when utilizing the Do Prior
        Auth platform for the purposes of completion of prior authorizations, enrollment forms, e-appeals or other
        available functionality.
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import SignaturePad from "signature_pad";

const signaturePad = ref(null);
const acceptTermsOfService = ref(props.signature !== null && props.signature !== "");
const saveButtonText = ref("Save");
const emit = defineEmits(["updateSignature"]);

const props = defineProps({
  signature: {
    type: String,
    default() {
      return "";
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

onMounted(() => {
  const canvas = document.querySelector("canvas");
  signaturePad.value = new SignaturePad(canvas, {
    backgroundColor: "rgb(255, 255, 255)",
    penColor: "rgb(0, 0, 0)",
  });
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  if (props.signature) {
    signaturePad.value.fromDataURL(props.signature);
    saveButtonText.value = "Saved";
  } else {
    signaturePad.value.clear();
    emit("updateSignature", "");
  }
});

const clearSignature = () => {
  signaturePad.value.clear();
  emit("updateSignature", "");
  saveButtonText.value = "Save";
};

const saveSignature = () => {
  if (!signaturePad.value.isEmpty()) {
    emit("updateSignature", signaturePad.value.toDataURL());
    saveButtonText.value = "Saved";
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
