<template>
  <div>
    <div v-if="primaryDiagnosis.icd10Code && primaryDiagnosis.diagnosis" class="grid-row">
      <div class="input-elements-group icd">
        <label for="icd">ICD 10 Code(s)</label>
        <input id="icd" v-model="primaryDiagnosis.icd10Code" :readonly="!props.isEditable" type="text" placeholder="" />
      </div>
      <div class="input-elements-group diagnosis">
        <label for="diagnosis">Primary Diagnosis</label>
        <input
          id="diagnosis"
          v-model="primaryDiagnosis.diagnosis"
          :readonly="!props.isEditable"
          type="text"
          placeholder="" />
      </div>
      <div v-if="props.isEditable" class="grid-control-element" @click="unSetPrimaryDiagnosis()">
        <img src="@/assets/images/trash.svg" alt="remove" />
      </div>
    </div>
    <div v-else>
      <div v-if="props.isEditable">
        <DiagnosticICD10CodeAutocomplete
          :data="{ label: 'Primary Diagnosis', searchId: generateRandom4DigitNumber() }"
          :required="true"
          @icd10-selected="selectedPrimaryDiagnosis = $event" />
      </div>
      <span v-if="!isPrimaryDiagnosisValid && props.formButtonClicked" class="input-error-notification">
        Please enter Primary Diagnosis.
      </span>
    </div>

    <div v-if="props.isSponsored" class="accented-table-container scrollable-table-container">
      <table>
        <thead>
          <tr>
            <th>ICD-10 Codes</th>
            <th>Common Primary Diagnoses</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="primaryDiagnosisOption in primaryDiagnosisReferenceOptions"
            :key="primaryDiagnosisOption.diagnosis">
            <td>{{ primaryDiagnosisOption.diagnosis.icd10Code }}</td>
            <td>{{ primaryDiagnosisOption.diagnosis.diagnosis }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="props.otherDiagnosis.length">
      <p>Secondary Diagnoses</p>
      <hr class="light-hr" />
      <div v-for="i in secondaryDiagnosisIndex" :key="i">
        <div v-if="otherDiagnosis[i - 1].icd10Code && otherDiagnosis[i - 1].diagnosis" class="grid-row">
          <div class="input-elements-group icd">
            <label :for="`other-diagnosis-icd10-${i - 1}`">ICD 10 Code(s)</label>
            <input
              :id="`other-diagnosis-icd10-${i - 1}`"
              v-model="otherDiagnosis[i - 1].icd10Code"
              type="text"
              :readonly="!props.isEditable"
              placeholder="" />
          </div>
          <div class="input-elements-group diagnosis">
            <label :for="`other-diagnosis-${i - 1}`">Diagnosis</label>
            <input
              :id="`other-diagnosis-${i - 1}`"
              v-model="otherDiagnosis[i - 1].diagnosis"
              :readonly="!props.isEditable"
              type="text"
              placeholder="" />
          </div>
          <div v-if="props.isEditable" class="grid-control-element" @click="unSetSecondaryDiagnosis(i - 1)">
            <img src="@/assets/images/trash.svg" alt="remove" />
          </div>
        </div>
        <div v-else>
          <div v-if="props.isEditable">
            <div v-if="secondaryDiagnosisIndex < secondaryDiagnosisMaxIndex">
              <DiagnosticICD10CodeAutocomplete
                :data="{ label: '', searchId: generateRandom4DigitNumber() }"
                @icd10-selected="selectedSecondaryDiagnosis = $event" />
            </div>
            <div v-else class="in-form-button-wrapper">
              <button @click="addSecondaryDiagnosis">+ Secondary Diagnosis</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="props.isSponsored" class="accented-table-container scrollable-table-container">
      <table>
        <thead>
          <tr>
            <th>ICD-10 Codes</th>
            <th>Common Secondary Diagnoses</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="secondaryDiagnosis in secondaryDiagnosisReferenceOptions" :key="secondaryDiagnosis.diagnosis">
            <td>{{ secondaryDiagnosis.diagnosis.icd10Code }}</td>
            <td>{{ secondaryDiagnosis.diagnosis.diagnosis }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onBeforeMount } from "vue";
import { generateRandom4DigitNumber } from "@/utils";
import { storeToRefs } from "pinia";
import { useRequirementsStore } from "@/stores/requirementsStore";
import { mainServices } from "@/services/mainServices";

import DiagnosticICD10CodeAutocomplete from "@/components/DiagnosticICD10CodeAutocomplete.vue";

const { requirementsData } = storeToRefs(useRequirementsStore());

const props = defineProps({
  primaryDiagnosis: {
    type: Object,
    default() {
      return {
        icd10Code: null,
        diagnosis: null,
      };
    },
  },
  otherDiagnosis: {
    type: Array,
    default() {
      return [
        {
          icd10Code: null,
          diagnosis: null,
        },
      ];
    },
  },
  medication: {
    type: String,
    default() {
      return "";
    },
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
  isSponsored: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const primaryDiagnosis = ref(props.primaryDiagnosis ? props.primaryDiagnosis : { icd10Code: null, diagnosis: null });
const otherDiagnosis = ref(props.otherDiagnosis ? props.otherDiagnosis : [{ icd10Code: null, diagnosis: null }]);
const selectedPrimaryDiagnosis = ref(null);
const selectedSecondaryDiagnosis = ref(null);
const secondaryDiagnosisMaxIndex = computed(() => otherDiagnosis.value.length);
const secondaryDiagnosisIndex = ref(1);
const primaryDiagnosisReferenceOptions = ref(null);
const secondaryDiagnosisReferenceOptions = ref(null);

const emit = defineEmits(["updatePrimaryDiagnosis", "updateOtherDiagnosis", "addOtherDiagnosis"]);

onBeforeMount(async () => {
  if (props.isEditable) {
    if (otherDiagnosis.value.findIndex((item) => !item.diagnosis) === -1) {
      addSecondaryDiagnosis();
    }
    secondaryDiagnosisIndex.value = otherDiagnosis.value.findIndex((item) => !item.diagnosis) + 1;
  }
  if (!props.isEditable) {
    secondaryDiagnosisIndex.value = otherDiagnosis.value.findIndex((item) => item.diagnosis) + 1;
  }
  if (!requirementsData.value.diagnosisRequirements && props.isSponsored && props.medication) {
    requirementsData.value.diagnosisRequirements = await mainServices.fetchDiagnosisRequirements({
      medication: props.medication,
    });
  }
  primaryDiagnosisReferenceOptions.value = prepareDiagnosisReferenceOptions("Primary");
  secondaryDiagnosisReferenceOptions.value = prepareDiagnosisReferenceOptions("Secondary");
});

const isPrimaryDiagnosisValid = computed(() => {
  if (props.primaryDiagnosis) {
    return props.primaryDiagnosis.icd10Code && props.primaryDiagnosis.diagnosis;
  } else {
    return false;
  }
});

function prepareDiagnosisReferenceOptions(type) {
  if (!requirementsData.value.diagnosisRequirements) {
    return [];
  } else {
    return requirementsData.value.diagnosisRequirements.filter((item) => item.diagnosisType === type);
  }
}

watch(selectedPrimaryDiagnosis, (newSelectedPrimaryDiagnosis) => {
  if (newSelectedPrimaryDiagnosis) {
    emit("updatePrimaryDiagnosis", {
      icd10Code: newSelectedPrimaryDiagnosis.icd10Code,
      diagnosis: newSelectedPrimaryDiagnosis.diagnosis,
    });
  }
});

function unSetPrimaryDiagnosis() {
  emit("updatePrimaryDiagnosis", {
    icd10Code: null,
    diagnosis: null,
  });
  selectedPrimaryDiagnosis.value = null;
}

watch(selectedSecondaryDiagnosis, (newSelectedSecondaryDiagnosis) => {
  if (newSelectedSecondaryDiagnosis) {
    emit("updateOtherDiagnosis", {
      index: secondaryDiagnosisIndex.value,
      icd10Code: newSelectedSecondaryDiagnosis.icd10Code,
      diagnosis: newSelectedSecondaryDiagnosis.diagnosis,
    });
    if (secondaryDiagnosisIndex.value < secondaryDiagnosisMaxIndex.value) {
      secondaryDiagnosisIndex.value++;
    }
  }
});

function unSetSecondaryDiagnosis(index) {
  emit("updateOtherDiagnosis", {
    index,
    icd10Code: null,
    diagnosis: null,
  });
  selectedSecondaryDiagnosis.value = null;
  secondaryDiagnosisIndex.value--;
}

function addSecondaryDiagnosis() {
  emit("addOtherDiagnosis");
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
