<template>
  <div class="patient-information-form-wrapper">
    <div class="patient-information-page-title">
      <h1>{{ orderInfo.insuranceProvider }}</h1>
      <h1>{{ orderInfo.insurancePlanType }} | {{ orderInfo.patientInfo.state }}</h1>
      <p>Personalize The Prior Authorization Steps For Your Patient.</p>
    </div>
    <div class="form">
      <div class="patient-information-part">
        <h5>Patient Drug History</h5>
        <hr class="bold-hr" />
        <div v-if="props.isSponsored">
          <div v-for="stepTherapyRequirement in orderInfo.stepTherapyRequired" :key="stepTherapyRequirement.label">
            <p>{{ stepTherapyRequirement.label }}</p>
            <div class="accented-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Required Step Medication</th>
                    <th>Duration</th>
                    <th>Contraindications</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in stepTherapyRequirement.stepTherapyItems" :key="item.stepOrder">
                    <td>{{ item.medicationRequired }}</td>
                    <td v-if="item.durationRequired && item.durationRequired.length">{{ item.durationRequired }}</td>
                    <td v-else>-</td>
                    <td v-if="item.contraindication && item.contraindication.length">{{ item.contraindication }}</td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-for="i in orderInfo.drugsTried.length" :key="i" class="fields-in-row">
          <div class="input-elements-group drug-tried">
            <label for="drug-tried">Drug Tried</label>
            <input id="drug-tried" v-model="orderInfo.drugsTried[i - 1].drugTried" type="text" placeholder="" />
          </div>
          <div class="input-elements-group start-date-trial">
            <label for="start-date-trial">Start Date Tried</label>
            <input id="start-date-trial" v-model="orderInfo.drugsTried[i - 1].startDate" type="date" placeholder="" />
          </div>
          <div class="input-elements-group start-date-trial">
            <label for="start-date-trial">End Date Tried</label>
            <input id="start-date-trial" v-model="orderInfo.drugsTried[i - 1].endDate" type="date" placeholder="" />
          </div>
          <div class="input-elements-group result-of-previous-drug-trial">
            <label for="result-of-previous-drug-trial">Result of Previous Drug Trial</label>
            <input
              id="result-of-previous-drug-trial"
              v-model="orderInfo.drugsTried[i - 1].resultOfTrial"
              type="text"
              placeholder="" />
          </div>
        </div>
        <div class="in-form-button-wrapper">
          <button @click="addDrugTried">+ Drug Tried</button>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button
          class="return-button"
          :class="{ 'button-clicked': draftSavingInProgress }"
          :disabled="draftSavingInProgress"
          @click="savePartialOrderInfo">
          Save Draft
        </button>
        <button class="next-button" :disabled="draftSavingInProgress" @click="handleNextClicked">Next</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { orderInfoService } from "@/services/orderInfoService";
import { initializeDrugsTried } from "@/utils";

const { orderInfo } = storeToRefs(useOrderInfoStore());
const draftSavingInProgress = ref(false);

const props = defineProps({
  isSponsored: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

onMounted(() => {
  orderInfo.value.formStep = "Step Therapies Information";
  if (!orderInfo.value.drugsTried.length) {
    initializeDrugsTried();
  }
});

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
  draftSavingInProgress.value = false;
}

function addDrugTried() {
  orderInfo.value.drugsTried.push({
    drugTried: null,
    startDate: null,
    endDate: null,
    resultOfTrial: null,
  });
}

const emit = defineEmits(["nextClicked"]);
const handleNextClicked = () => {
  savePartialOrderInfo();
  emit("nextClicked");
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
