<template>
  <div class="recursive-wrapper">
    <input
      v-if="props.option.nodeType === 'radio' || props.option.nodeType === 'checkbox'"
      :id="`${inputFieldId}`"
      :type="props.option.nodeType"
      :checked="isChecked"
      @click="clickOnOption" />
    <label
      :for="`${inputFieldId}`"
      :class="{
        'radio-label': props.option.nodeType === 'radio' || props.option.nodeType === 'checkbox',
      }">
      {{ option.label }}
    </label>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { generateRandom4DigitNumber } from "@/utils";

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["clickedRequirementOption"]);

const isChecked = ref(false);
const inputFieldId = generateRandom4DigitNumber();

function clickOnOption() {
  isChecked.value = !isChecked.value;
  emit("clickedRequirementOption", props.option);
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_questionnaire-recursive-component.scss";
</style>
