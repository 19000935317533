<template>
  <PriorHeader />
  <div class="registered-form-wrapper">
    <h1 class="registration-page-title">Login</h1>
    <GreenCirclePreloader v-if="showPreloader" />
    <div v-else class="login-form form">
      <div v-if="!secondVerificationStep">
        <div class="your-email">
          <label for="your-email">Email Address</label>
          <input
            id="your-email"
            v-model="credentials.email"
            type="text"
            placeholder="example@findsunrise.com"
            @keyup="(event) => sendFormByEnterClicking(event, loginUser)" />
          <span v-if="!isEmailValid && formButtonClicked" class="input-error-notification">
            Please enter a valid email address.
          </span>
        </div>
        <div class="password">
          <label for="password">Password</label>
          <input
            id="password"
            v-model="credentials.password"
            type="password"
            @keyup="(event) => sendFormByEnterClicking(event, loginUser)" />
          <span v-if="!credentials.password && formButtonClicked" class="input-error-notification">
            Please enter a password.
          </span>
          <span v-else-if="!isPasswordValid && formButtonClicked" class="input-error-notification">
            Please enter a valid password (at least 11 characters, at least 1 uppercase and 1 lowercase letter, 1
            number, and 1 symbol).
          </span>
        </div>
        <div class="button-wrapper">
          <button @click="loginUser">Login</button>
          <span v-if="errors.length > 0" class="input-error-notification">
            <span v-for="error in errors" :key="error">{{ error }}</span>
          </span>
        </div>
        <div class="auxiliary-account-links">
          <router-link :to="{ name: 'password-reset-request' }" class="forgot-pass-link">Forgot Password ?</router-link>
          <span class="have-an-account">
            Dont have an account ?
            <router-link :to="{ name: 'register' }">Create an account</router-link>
          </span>
        </div>
      </div>
      <div v-else>
        <p>Check your email, enter One Time Password (OTP):</p>
        <input v-model="otpCode" placeholder="Enter OTP from your email" />
        <div class="button-wrapper">
          <button @click="verifyOtp">Verify OTP</button>
          <span v-if="errors.length > 0" class="input-error-notification">
            <span v-for="error in errors" :key="error">{{ error }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, computed } from "vue";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores";
import { tryParseApiErrors, sendFormByEnterClicking } from "@/utils";
import { FORM_VALIDATION_PATTERNS } from "@/utils/constants";

const secondVerificationStep = ref(false);
const otpCode = ref("");
const errors = ref([]);
const formButtonClicked = ref(false);
const router = useRouter();
const authStore = useAuthStore();
const showPreloader = ref(false);

const credentials = ref({
  email: "",
  password: "",
});

// Validators
const isEmailValid = computed(() => {
  const email = credentials.value.email;
  return email !== "" && FORM_VALIDATION_PATTERNS.EMAIL_PATTERN.test(email);
});

const isPasswordValid = computed(() => {
  const password = credentials.value.password;
  return FORM_VALIDATION_PATTERNS.PASSWORD_PATTERN.test(password);
});

const loginUser = async () => {
  formButtonClicked.value = true;
  if (isEmailValid.value && isPasswordValid.value) {
    showPreloader.value = true;
    try {
      let otpConfig = await authStore.login(credentials.value);
      if (otpConfig.secondStep) {
        secondVerificationStep.value = true;
      } else {
        await authStore.loginStep2NotRequired();
        router.push({ name: "insurance-navigator-page" });
      }
      errors.value = [];
    } catch (error) {
      errors.value = tryParseApiErrors(error);
    }
    showPreloader.value = false;
  }
};

const verifyOtp = async () => {
  showPreloader.value = true;
  try {
    await authStore.loginStep2(otpCode.value);
    router.push({ name: "insurance-navigator-page" });
    // Reset the second step verification state
    secondVerificationStep.value = false;
  } catch (error) {
    errors.value = tryParseApiErrors(error);
  } finally {
    showPreloader.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_authorisation-forms.scss";
</style>
