<template>
  <div class="checklist-wrapper">
    <div class="questionnaire-title">
      <h1>{{ orderInfo.insuranceProvider }}</h1>
      <h1>
        {{ orderInfo.insurancePlanType }}
        <span v-if="orderInfo.insurancePlanType && orderInfo.insurancePlanType.length">|</span>
        {{ orderInfo.patientInfo.state }}
      </h1>
      <p>Personalize The Prior Authorization Steps For Your Patient.</p>
    </div>
    <div class="questionnaire">
      <div v-if="currentRequirement">
        <div>
          <h3>{{ currentRequirement.label }}</h3>
          <div v-for="option in applicableOptions" :key="option.optionRuleName">
            <span v-if="option.nodeType === 'radio' || option.nodeType === 'checkbox'">
              <QuestionnaireOptionComponent
                :option="option"
                @clicked-requirement-option="setSessionRequirementOptions" />
            </span>
            <span v-else-if="option.nodeType === 'textarea'" class="custom-input-fields">
              <textarea
                :id="`${option.optionRuleName}`"
                v-model="textAreaInput"
                class="textarea"
                rows="8"
                :placeholder="option.label"></textarea>
            </span>
            <span v-else class="custom-input-fields">
              <input
                :id="`${option.optionRuleName}`"
                v-model="textInput"
                :type="option.nodeType"
                :placeholder="option.label" />
            </span>
          </div>
          <div class="buttons-wrapper">
            <button v-if="currentRequirementIndex > 0" class="return-button" @click="unSetPreviousSessionRequirements">
              Back
            </button>
            <button
              v-if="currentRequirement.requirementOptions[0].nodeType !== 'radio'"
              class="next-button"
              @click="setSessionRequirements">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import QuestionnaireOptionComponent from "@/components/QuestionnaireOptionComponent.vue";

const emit = defineEmits(["nextClicked"]);
const { orderInfo } = storeToRefs(useOrderInfoStore());
const sortedRequirements = ref(sortRequirements(orderInfo.value.payerQuestions));
const sessionRequirements = ref([]);
const sessionRequirementOptions = ref([]);
const sessionRequirementTextInputs = ref([]);
const currentRequirementIndex = ref(null);
const currentRequirement = ref(null);
const applicableOptions = ref([]);
const sessionLabResults = ref([]);
const sessionPatientChartNotes = ref([]);

const textAreaInput = ref(null);
const textInput = ref(null);
const textInputExpected = ref(false);

onMounted(() => {
  currentRequirementIndex.value = 0;
  orderInfo.value.formStep = "Payer Questions";
});

function assignTextInputs() {
  if (textInputExpected.value) {
    sessionRequirementTextInputs.value.push({
      id: currentRequirement.value.requirementOptions[0].optionRuleName,
      label: textAreaInput.value ? textAreaInput.value : textInput.value ? textInput.value : "",
    });
    sessionRequirementOptions.value.push(currentRequirement.value.requirementOptions[0].optionRuleName);
    textAreaInput.value = null;
    textInput.value = null;
    textInputExpected.value = false;
  }
}

function unSetOptionSelection(option) {
  if (sessionRequirementOptions.value.includes(option.optionRuleName)) {
    sessionRequirementOptions.value.splice(sessionRequirementOptions.value.indexOf(option.optionRuleName), 1);
    if (option.labResults.length) {
      option.labResults.forEach((labResult) => {
        if (sessionLabResults.value.includes(labResult)) {
          sessionLabResults.value.splice(sessionLabResults.value.indexOf(labResult), 1);
        }
      });
    }
    if (option.patientChartNotes.length) {
      option.patientChartNotes.forEach((patientChartNote) => {
        if (sessionPatientChartNotes.value.includes(patientChartNote)) {
          sessionPatientChartNotes.value.splice(sessionPatientChartNotes.value.indexOf(patientChartNote), 1);
        }
      });
    }
  }
  if (sessionRequirementTextInputs.value.find((t) => t.id === option.optionRuleName)) {
    sessionRequirementTextInputs.value.splice(
      sessionRequirementTextInputs.value.indexOf(
        sessionRequirementTextInputs.value.find((t) => t.id === option.optionRuleName)
      ),
      1
    );
  }
}

function unSetPreviousSessionRequirements() {
  const poppedRequirement = sessionRequirements.value.pop();
  const optionsToUnset = sortedRequirements.value.find(
    (r) => r.requirementRuleName === poppedRequirement
  ).requirementOptions;
  for (const option of optionsToUnset) {
    unSetOptionSelection(option);
  }
  const previousApplicableIndex = sortedRequirements.value.findIndex(
    (r) => r.requirementRuleName === poppedRequirement
  );
  currentRequirementIndex.value = previousApplicableIndex;
}

function setSessionRequirementOptions(requirementOption) {
  if (sessionRequirementOptions.value.includes(requirementOption.optionRuleName)) {
    unSetOptionSelection(requirementOption);
    return;
  } else {
    sessionRequirementOptions.value.push(requirementOption.optionRuleName);
    if (requirementOption.labResults.length) {
      requirementOption.labResults.forEach((labResult) => {
        sessionLabResults.value.push(labResult);
      });
    }
    if (requirementOption.patientChartNotes.length) {
      requirementOption.patientChartNotes.forEach((patientChartNote) => {
        sessionPatientChartNotes.value.push(patientChartNote);
      });
    }
    if (requirementOption.nodeType === "radio") {
      setSessionRequirements();
    }
  }
}

const ruleSetChecked = () => {
  const requirement = sortedRequirements.value[currentRequirementIndex.value];
  if (!requirement.requirementRuleSet || requirement.requirementRuleSet.length === 0) {
    applicableOptions.value = [...requirement.requirementOptions];
    return true;
  }
  for (const rule of requirement.requirementRuleSet) {
    if (!sessionRequirements.value.includes(rule)) {
      return false;
    }
  }
  let optionsFailedRuleCheck = [];
  for (const option of requirement.requirementOptions) {
    for (const optionRule of option.optionRuleSet) {
      if (!sessionRequirementOptions.value.includes(optionRule)) {
        optionsFailedRuleCheck.push(option);
      }
    }
  }
  let tempRequirement = JSON.parse(JSON.stringify(requirement));
  for (const option of optionsFailedRuleCheck) {
    tempRequirement.requirementOptions.splice(
      tempRequirement.requirementOptions.findIndex((o) => o.optionRuleName === option.optionRuleName),
      1
    );
  }
  if (tempRequirement.requirementOptions.length === 0) {
    return false;
  } else {
    applicableOptions.value = [...tempRequirement.requirementOptions];
    const electiveRequirementRuleSet = tempRequirement.electiveRequirementRuleSet;
    if (electiveRequirementRuleSet && electiveRequirementRuleSet.length > 0) {
      let electiveRequirements = [];
      for (const electiveRequirement of electiveRequirementRuleSet) {
        for (const rule of electiveRequirement) {
          if (sessionRequirements.value.includes(rule)) {
            electiveRequirements.push(electiveRequirement);
          }
        }
      }
      if (electiveRequirements.length === 0) {
        return false;
      }
      let electiveOptions = [];
      for (const option of tempRequirement.requirementOptions) {
        const electiveOptionRuleSet = option.electiveOptionRuleSet;
        if (electiveOptionRuleSet && electiveOptionRuleSet.length > 0) {
          for (const electiveOption of electiveOptionRuleSet) {
            for (const rule of electiveOption) {
              if (sessionRequirementOptions.value.includes(rule)) {
                electiveOptions.push(option);
              }
            }
          }
        }
      }
      if (electiveOptions.length === 0) {
        return false;
      }
      return true;
    }
    return true;
  }
};

const setSessionRequirements = () => {
  assignTextInputs();
  sessionRequirements.value.push(currentRequirement.value.requirementRuleName);
  if (currentRequirementIndex.value === sortedRequirements.value.length - 1) {
    setSelectionDataAndMoveToNext();
  } else {
    currentRequirementIndex.value++;
  }
};

watch(currentRequirementIndex, () => {
  if (ruleSetChecked()) {
    currentRequirement.value = sortedRequirements.value[currentRequirementIndex.value];
    if (
      currentRequirement.value.requirementOptions[0].nodeType === "text" ||
      currentRequirement.value.requirementOptions[0].nodeType === "textarea" ||
      currentRequirement.value.requirementOptions[0].nodeType === "date"
    ) {
      textInputExpected.value = true;
    }
  } else {
    if (currentRequirementIndex.value === sortedRequirements.value.length - 1) {
      setSelectionDataAndMoveToNext();
    } else {
      currentRequirementIndex.value++;
    }
  }
});

function setSelectionDataAndMoveToNext() {
  const selection = {
    sessionLabResults: sessionLabResults.value,
    sessionPatientChartNotes: sessionPatientChartNotes.value,
  };
  for (const requirement of sessionRequirements.value) {
    const requirementData = {};
    requirementData.id = requirement;
    requirementData.label = sortedRequirements.value.find((r) => r.requirementRuleName === requirement).label;
    requirementData.options = [];
    for (const option of sessionRequirementOptions.value) {
      const optionTemplate = sortedRequirements.value
        .find((r) => r.requirementRuleName === requirement)
        .requirementOptions.find((o) => o.optionRuleName === option);
      if (!optionTemplate) continue;
      if (
        optionTemplate.nodeType === "textarea" ||
        optionTemplate.nodeType === "text" ||
        optionTemplate.nodeType === "number" ||
        optionTemplate.nodeType === "date"
      ) {
        requirementData.options.push({
          label: sessionRequirementTextInputs.value.find((t) => t.id === option).label,
          id: option,
        });
      } else {
        requirementData.options.push({ label: optionTemplate.label, id: option });
      }
    }
    orderInfo.value.checklist.push(requirementData);
  }
  emit("nextClicked", selection);
}

function sortRequirements(requirements) {
  const sortedRequirements = [];
  const requirementsWithRules = [];
  const requirementsWithNoRules = [];
  for (const requirement of requirements) {
    if (requirement.requirementRuleSet && requirement.requirementRuleSet.length > 0) {
      requirementsWithRules.push(requirement);
    } else {
      requirementsWithNoRules.push(requirement);
    }
  }
  requirementsWithRules.sort((a, b) => {
    if (a.requirementRuleSet.includes(b.requirementRuleName)) {
      return 1;
    } else if (b.requirementRuleSet.includes(a.requirementRuleName)) {
      return -1;
    } else {
      return 0;
    }
  });
  sortedRequirements.push(...requirementsWithNoRules);
  sortedRequirements.push(...requirementsWithRules);
  return sortedRequirements;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_questionnaire-page.scss";
</style>
