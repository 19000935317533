<template>
  <div class="dropdown search-dropdown-block" :class="{ expanded: isDropdownOpen }">
    <input
      v-if="props.allowFreeTextInput"
      :id="`dropdown-input-${props.placeholder}`"
      v-model="searchText"
      type="text"
      class="dropdown-input"
      :placeholder="`${props.placeholder}`"
      @click="toggleDropdown"
      @keyup="getFreeTextInput"
      @keyup.esc="toggleDropdown" />
    <input
      v-else
      :id="`dropdown-input-${props.placeholder}`"
      v-model="searchText"
      type="text"
      class="dropdown-input"
      :placeholder="`${props.placeholder}`"
      @click="toggleDropdown"
      @keyup.esc="toggleDropdown" />
    <ul v-show="isDropdownOpen" class="dropdown-options">
      <li>
        <div class="checked-item list-item-text">
          <input
            :id="`select-all-${props.placeholder}`"
            type="checkbox"
            value="Select All"
            @change="optionSelected('Select All')" />
          <label :for="`select-all-${props.placeholder}`"> Select All </label>
        </div>
      </li>
      <li v-for="option in filteredOptions" :key="option">
        <div class="checked-item">
          <input
            :id="option"
            v-model="selectedOptions"
            type="checkbox"
            :value="option"
            @change="optionSelected(option)" />
          <label :for="option">
            {{ option }}
          </label>
        </div>
      </li>
    </ul>
    <div v-if="selectedOptions.length" class="selection">{{ selectedOptions.join(", ") }}</div>
  </div>
</template>

<script setup>
import { watch, ref } from "vue";

const props = defineProps({
  placeholder: {
    type: String,
    default() {
      return "Please Select";
    },
  },
  options: {
    type: Array,
    required: true,
  },
  allowFreeTextInput: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const emit = defineEmits(["selectionChange"]);
const selectedOptions = ref([]);
const isDropdownOpen = ref(false);
const searchText = ref("");
const optionsForDropdown = ref(props.options);
const filteredOptions = ref(props.options);

const toggleDropdown = () => {
  if (isDropdownOpen.value) {
    searchText.value = "";
  }
  isDropdownOpen.value = !isDropdownOpen.value;
};

watch(searchText, (newValue) => {
  isDropdownOpen.value = true;
  filteredOptions.value = optionsForDropdown.value.filter((option) =>
    option.toLowerCase().includes(newValue.toLowerCase())
  );
});

function optionSelected(option) {
  if (option === "Select All") {
    if (selectedOptions.value.length === optionsForDropdown.value.length) {
      selectedOptions.value = [];
    } else {
      selectedOptions.value = optionsForDropdown.value;
    }
  }
  emit("selectionChange", selectedOptions.value);
}

function getFreeTextInput(event) {
  if ((event.code === "Enter" || event.code === 76) && searchText.value.length) {
    const freeTextInputItem = searchText.value;
    selectedOptions.value.push(freeTextInputItem);
    optionsForDropdown.value.push(freeTextInputItem);
    emit("selectionChange", selectedOptions.value);
    searchText.value = "";
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/components/_checkbox-multiple-select-component.scss";
</style>
