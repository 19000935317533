<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div class="patient-information-part">
        <h5>View Patient Records</h5>
        <div>
          <form @submit.prevent="searchPatientRecords">
            <div class="fields-in-row">
              <div class="input-elements-group">
                <label for="date-from">Date From</label>
                <input
                  id="date-from"
                  v-model="dateFrom"
                  type="date"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
              <div class="input-elements-group">
                <label for="date-to">Date To</label>
                <input
                  id="date-to"
                  v-model="dateTo"
                  type="date"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
              <div class="input-elements-group half-form-container-width">
                <label for="search-query">Filter by Keyword</label>
                <input
                  id="search-query"
                  v-model="searchQuery"
                  placeholder="eg. Diagnosis"
                  type="text"
                  @keyup="(event) => sendFormByEnterClicking(event, searchPatientRecords)" />
              </div>
            </div>
          </form>
        </div>
        <div>
          <div class="accented-table-container scrollable-table-container">
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td>Loading...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-if="!fetchedPatientRecords.length && !loading">
                  <td>No records found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-for="record in displayedPatientRecords" :key="record">
                  <td>{{ record.category }}</td>
                  <td>
                    <span v-for="item in record.description" :key="item">{{ titleCaseString(item) }}<br /></span>
                  </td>
                  <td>{{ record.dateOfRecord }}</td>
                  <td>
                    <CheckboxWithLabel :record="record" @update-record="updateRecordInSubmission" />
                  </td>
                  <td>
                    <span v-if="record.downloadLink"><a class="download" :href="record.downloadLink">Download</a></span>
                    <span v-else><button class="download" @click="showDataPreview(record.details)">View</button></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="openDataPreview">
            <div class="light-background-section">
              <ul>
                <li v-for="item in dataPreview" :key="item">
                  <span name="list-item-text"> {{ item }} </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="in-form-button-wrapper">
            <button @click="showAttachment">Preview Attachment</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PreviewPatientRecordsAttachment v-if="previewAttachment" :patient-records="patientRecords" />
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { orderInfoService } from "@/services/orderInfoService";
import { sendFormByEnterClicking, titleCaseString, titleCaseMixedString } from "@/utils";
import PreviewPatientRecordsAttachment from "@/components/PreviewPatientRecordsAttachment.vue";

import CheckboxWithLabel from "@/components/CheckboxWithLabel.vue";

const patientRecords = ref([]);
const fetchedPatientRecords = ref([]);
const displayedPatientRecords = ref([]);
const dateFrom = ref(null);
const dateTo = ref(null);
const searchQuery = ref("");
const openDataPreview = ref(false);
const dataPreview = ref(null);
const loading = ref(false);
const previewAttachment = ref(false);

const props = defineProps({
  orderKey: {
    type: String,
    required: true,
  },
});

function showAttachment() {
  previewAttachment.value = !previewAttachment.value;
}

function updateRecordInSubmission(record, value) {
  if (value) {
    patientRecords.value.push(record);
  } else {
    patientRecords.value = patientRecords.value.filter((item) => item !== record);
  }
}

function formattedDate(dateString) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}\+\d{2}:\d{2}$/;
  const createdRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}[A-Z]$/;
  if (dateRegex.test(dateString) || createdRegex.test(dateString)) {
    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) {
      return dateString;
    } else {
      return dateObj.toLocaleString();
    }
  } else {
    return dateString;
  }
}

onBeforeMount(async () => {
  fetchedPatientRecords.value = await orderInfoService.fetchPatientRecords(props.orderKey);
  if (fetchedPatientRecords.value.length) {
    fetchedPatientRecords.value.forEach((record) => {
      record.category = titleCaseString(record.category);
      record.details = Object.entries(record)
        .map(([key, value]) => `${titleCaseMixedString(key)}: ${formattedDate(value) || value}`)
        .filter((item) => item !== "Details");
      record.searchableData = Object.entries(record)
        .map(([key, value]) => `${key}: ${value}`)
        .join(" ")
        .toLowerCase();
    });
  }
  fetchedPatientRecords.value.sort((a, b) => {
    const dateA = a.dateOfRecord ? a.dateOfRecord : a.createdAt ? a.createdAt : a.authored ? a.authored : null;
    const dateB = b.dateOfRecord ? b.dateOfRecord : b.createdAt ? b.createdAt : b.authored ? b.authored : null;
    if (!dateA || !dateB) {
      return !dateA ? 1 : -1;
    }
    return new Date(dateB) - new Date(dateA);
  });
  displayedPatientRecords.value = fetchedPatientRecords.value;
});

function showDataPreview(data) {
  dataPreview.value = data;
  openDataPreview.value = true;
}

function searchPatientRecords() {
  loading.value = true;
  openDataPreview.value = false;
  dataPreview.value = null;
  let filteredRecords = fetchedPatientRecords.value.filter((record) => {
    const searchTerms = searchQuery.value.toLowerCase().split(" ");
    return searchTerms.every((term) => record.searchableData.includes(term));
  });
  if (dateFrom.value) {
    filteredRecords = filteredRecords.filter((record) => record.dateOfRecord >= dateFrom.value || !record.dateOfRecord);
  }
  if (dateTo.value) {
    filteredRecords = filteredRecords.filter((record) => record.dateOfRecord <= dateTo.value || !record.dateOfRecord);
  }
  displayedPatientRecords.value = filteredRecords;
  loading.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
