import { defineStore } from "pinia";
import { ref } from "vue";

export const usePdfSubmissionStore = defineStore("pdf-submission", () => {
  const pdfSubmission = ref({
    patientInfo: {
      id: null,
      prefix: null,
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      dob: null,
      insuranceId: null,
    },
    orderKey: null,
    medication: null,
    insuranceProvider: null,
    insurancePlanType: null,
    benefitType: null,
    priorAuthFormId: null,
    formSubmitted: null,
    supportingDocs: [],
    priorAuthNumber: null,
    priorAuthExpiration: null,
    submitForProcessing: null,
    signature: null,
  });

  return {
    pdfSubmission,
  };
});
