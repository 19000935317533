import api from "@/services/api";

const { post, get } = api.getPrefixedMethods("analytics");

export const analyticsServices = {
  logRequirementsSearchAction(data) {
    return post(`log-requirements-search/`, data);
  },
  healthCheck() {
    return get(`health-check/`);
  },
};
